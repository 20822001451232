import React from 'react'
import Datepicker from '../DropDown/Datepicker';
import { getCSV_Data } from "../../api/ReportingDashboard/reportingAPIs"

const SearchOptions = () => {
    function convertToCSV(data) {
        const header = Object.keys(data[0]).join(',');
        const rows = data.map(item => Object.values(item).join(','));
        return [header, ...rows].join('\n');
    }
    
    const generateCSVReport = async () => {
        try {
            // Fetch your CSV data using your API function
            const csvData = await getCSV_Data();
            console.log(csvData);
            const csv = await convertToCSV(csvData.data);
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = "report.csv";
            a.style.display = 'none';

            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating CSV report: ', error);
        }
    };
  return (
      <div>
          <div className="inline-flex space-x-2 mt-3 mb-3">
              {/* Classic Dropdown */}
              <div>
                  {/* <h2 className="text-2xl text-slate-800 font-bold mb-6">Datepicker (built with <a className="underline hover:no-underline" href="https://github.com/flatpickr/flatpickr" target="_blank" rel="noreferrer">flatpickr</a>)</h2> */}
                  <Datepicker />
              </div>
              {/* Country */}
              {/* <div>
                  <select id="country" className=" rounded-lg">
                      <option>Select Country *</option>
                      <option>Pakistan</option>
                      <option>Bangladesh</option>
                  </select>
              </div> */}
              {/* Site */}
              {/* <div>
                  <select id="site" className=" rounded-lg">
                      <option>Select Site *</option>
                      <option>PAK-TEST-001</option>
                      <option>BBAN-TEST-001</option>
                      <option>PAK-TEST-002</option>
                      <option>PAK-TEST-003</option>
                  </select>
              </div> */}

              {/* submit Button */}
                <div>
                  <button className=" bg-[#0F6CBD] hover:bg-[#1b82dd] text-white font-bold py-2 px-4 rounded-lg">
                        Search
                    </button>
                  <button
                      className="bg-[#12c548] hover:bg-[#80eb1d] text-white font-bold py-2 px-2 ml-2 rounded-lg"
                      onClick={generateCSVReport} // Call the function on button click
                  > Generate Report
                    </button>
                    </div>
          </div>
      </div>

  )
}

export default SearchOptions