import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PatientManagement from '../../Components/Tables/PatientManagement';
import { getPatients } from '../../api/Patient';
import { syncDB } from '../../api/RedCap/SyncPatients';
import PageTitle from '../../Components/utils/PageTitle';
import { Link } from 'react-router-dom';
import ModalLayout from '../../Components/Modals/ModalLayout';
import DataTableTemplate from '../../Components/Tables/DataTableTemplate';
import { toast } from 'react-toastify';


// Columns for Data Table
const columns = [
  // {
  //   name: '#',
  //   selector: row => row.id,
  //   sortable: true,
  // },
  {
    name: 'Registration Id',
    // selector: 'Name',
    selector: row => row.reg_id,

    sortable: true,
    // cell: row => <a href={row.} target="_blank" rel="noopener noreferrer">{row.website}</a>
    cell: row => <Link className='underline  text-blue-600' to={"/patient-Details/" + row.TBRegistrationNumber}>{row.reg_id}</Link>
  }, 
  {
    name: 'Site Id',
    // selector: 'Name',
    selector: row => row.Site_id,

    sortable: true,
    // cell: row => <a href={row.} target="_blank" rel="noopener noreferrer">{row.website}</a>
    // cell: row => <span className='underline  text-blue-600' >{row.TBRegistrationNumber}</span>
  },
  // {
  //   name: 'Registration No',
  //   // selector: row => "Message # "+row.MessageIndex,
  //   selector: row => row.TBRegistrationNumber,
  //   sortable: true,
  // },
  {
    name: 'Registration Date',
    selector: row => row.ScreeningDate,
    sortable: true,

    // conditionalCellStyles: [
    //   {
    //     when: row => row.Language === "Urdu",
    //     style: {
    //       backgroundColor: 'rgba(63, 195, 128, 0.9)',
    //       color: 'white',
    //       '&:hover': {
    //         cursor: 'pointer',
    //       },
    //     },
    //   },
    //   {
    //     when: row => row.Language === "Bangla",
    //     style: {
    //       backgroundColor: 'rgba(254, 60, 60, 0.9)',
    //       color: 'white',
    //       '&:hover': {
    //         cursor: 'pointer',

    //       },

    //     },

    //   }]
  },

  {
    name: 'Phone No',
    selector: row => row.PhoneNumber,
    sortable: true,

  },

  {
    name: 'Country',
    selector: row => row.Country,
    sortable: true,
    // cell: row => <span className="px-2 inline-flex text-xs leading-5 font-poppins font-semibold rounded-full bg-red-100 text-red-800"> {row.DailySmoking === 1 ? 'Yes' : 'No'}</span>
  },
  {
    name: 'Status',
    selector: row => row.PatientStatus,
    sortable: true,
    cell: row => <span className="px-2 inline-flex text-xs leading-5 font-poppins font-semibold rounded-full bg-green-100 text-green-800"> {row.PatientStatus}</span>
  },

];

const initialState = {
  PatientStatus: "Active",
  WillingToQuitSmoking: 1,
  DailySmoking: 1,
  QuitSmokingDate: "",
}

const PatientPortal = () => {
  const [Patients, setPatients] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [EditPatientModalOpen, setEditPatientModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [formData, setformData] = useState(initialState);
  
  //* calling api to get Table data
  useEffect(() => {
    document.title = "Patient Management";
    Patient_data(); 
  }, []);
  // * Get Patients from API
  const Patient_data = async () => {
    const patient = await getPatients();
    //  setPatients(patient);
    try {
      if (patient.length > 0) {
        setPatients(patient);
        toast.success("Patients Loaded Successfully");
      } else {
        setPatients([]);
        toast.error("No Patients Found");
      }

    } catch (error) {
      setPatients([]);
      toast.error("Something went wrong");
      
    }
  
  }

  // * Action context for selected rows
  const contextActions = useMemo(() => {
    return (
      <>
        <div className="flex justify-end gap-2 hidden ">
          {/* {selectedRows.length < 2 ?
            <button type="button" key="edit" onClick={(e) => { e.stopPropagation(); handleAction('edit', selectedRows) }} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M6.3 12.3l10-10a1 1 0 011.4 0l4 4a1 1 0 010 1.4l-10 10a1 1 0 01-.7.3H7a1 1 0 01-1-1v-4a1 1 0 01.3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 012 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h6a1 1 0 010 2H4v14h14v-6z" />
              </svg>
              Edit
            </button> : null
          } */}
          <button type="button" key="delete" onClick={() => handleAction('delete', selectedRows)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" />
            </svg>
            Delete
          </button>
        </div>
      </>
    );
  }, [selectedRows]);

  // * handle row selection (checkbox)
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, [selectedRows]);

  // * handle actions on selected rows
  const handleAction = async (action, row) => {

    switch (action) {
      case 'edit':
        console.log("open edit modal");
        console.log("selected row:", row[0]);
        setEditPatientModalOpen(true);
        break;
      case 'delete':
        const confirm = window.confirm("Are you sure you want to delete this record?");
        if (confirm) {
          // extract ids of all selected row
          const ids = row.map((item) => item.id);
          console.log(ids);

          // await deleteMessageTemplate(ids);
          // getMessageTemplatesData();
          // toast.success("Message Template Deleted Successfully");

          // create an array of objects with just the id property
          // const idObjects = ids.map((id) => ({ id }));
          // console.log(idObjects);
          // delete selected rows
          // await deleteData(currentTable, idObjects);
          // await deleteData(currentTable, ids);
          // fetchData(currentTable);
          setToggleClearRows(!toggledClearRows);
        }

        break;
      default:
        break;
    }
  };

  //* calling api to sync database
  const syncDatabase = async () => {
    await syncDB();
  }

  // * handle form input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setformData({ ...formData, [name]: value });
  };
  // * handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    setEditPatientModalOpen(false);
    console.log(formData);
    // setFormData(initialState);
  };

  //* search filter
  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return Patients;
    }

    return Patients?.filter(row => {
      return Object?.values(row)?.some(value => {
        if (typeof value !== 'string') {
          return false;
        }
        return value?.toLowerCase().includes(searchQuery?.toLowerCase())
      }
      );
    });
  }, [Patients, searchQuery]);


  return (
    <>
    <PageTitle title="Patient Management" />
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        
        {/* Right: Actions */}
        <div className=" grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 hidden">
          {/* Sync DB button */}
          {/* <button className="btn bg-[#0F6CBD] hover:bg-indigo-600 text-white" onClick={syncDatabase}>
              <AiOutlineSync className='text-white' size={20}/>
                  <span className="hidden xs:block ml-2">Sync Database</span>
                </button> */}
        </div>

      </div>
      <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">


        <div className='mt-2 p-4'>
          {/* Table */}
          <div className="overflow-x-auto ">
            {/* <input
              type="text"
              placeholder="Search..."
              class=" float-right px-4 mb-2 py-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
              value={searchQuery}
              onChange={event => setSearchQuery(event.target.value)}
            /> */}

            <div class="relative float-right">
              <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              </div>
              <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
                value={searchQuery}
                onChange={event => setSearchQuery(event.target.value)}
                placeholder="Search..." />
              {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
            </div>
            <DataTableTemplate columns={columns} data={filteredData}
              title={"Patients List"}
              contextActions={contextActions}
              handleRowSelected={handleRowSelected}
              toggledClearRows={toggledClearRows}
              setToggleClearRows={setToggleClearRows}
              isselectableRows = {false}
            />

          </div>
        </div>
        {/* <div> */}
        {/* Table */}
        {/* <div className="overflow-x-auto">
          <table className="table-auto w-full"> */}
        {/* Table header */}
        {/* <thead className="text-xs font-poppins font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <span className="sr-only">#</span>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold text-left">Registration No</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold text-left">Registration Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold text-left">Is Smoking</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold">Status</div>
                </th> 
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-poppins font-semibold">Action</div>
                </th>
                
              </tr>
            </thead> */}
        {/* Table body */}
        {/* <tbody className="text-sm divide-y divide-slate-200">
              {

                Patients && Patients.length>0 ? Patients.map((patient,index) => {
                  return (
                    
                    <PatientManagement
                      key={patient.id}
                      id={patient.id}
                      name={patient.Name}
                      reg_no={patient.TBRegistrationNumber}
                      reg_date={patient.ScreeningDate}
                      smokingStatus={patient.DailySmoking}
                      patient_status={patient.PatientStatus}
                    />
                  )
                }):null
              }
            </tbody> */}
        {/* </table> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    

    </>

  );
}

export default PatientPortal;
