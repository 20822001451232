import { getAxiosInstance } from "./Config";

//* GET MESSAGE TEMPLATE DATA FROM DATABASE
export const getMessageTemplates = async (country) => {
  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/messageTemplate/getMessageTemplateData", {
      params: { country: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if(error.message === "Request failed with status code 403"){
        alert("You are not authorized to view message template data");
      }
        
    });
};

// * add new message template to database
export const addMessageTemplate = async ( formData ) => {
  console.log("formData", formData);
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .post("/messageTemplate/createMessageTemplate", 
       formData 
      )
    .then((response) => {
      if (!response.ok || response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response;
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
};

// * update message template data in database
export const updateMessageTemplate = async ( formData ) => {
  console.log("formData", formData);
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .patch("/messageTemplate/updateMessageTemplate", 
       formData 
    )
    .then((response) => {
      if (!response.ok || response.status !== 201) {
        throw new Error(`Error: ${response.data}`);
      }
      return response;
    })
    .catch((error) => {
      console.error(error.message);
      return error;
      // alert(error.message);
    });
};




// * delete messageTemplate from database
export const deleteMessageTemplate = async (ids) => {
    console.log("ids", ids);
    const axiosInstance = await getAxiosInstance();
    try {
             return axiosInstance
               .delete("/messageTemplate/deleteMessageTemplate", {
                 data: { ids },
               })
               .then((response) => {
                 if (response.status !== 200) {
                   throw new Error(`Error: ${response.data}`);
                 }
                 return response.data;
               })
               .catch((error) => {
                 console.error(error.message);
                 return error.message;
                 // alert(error.message);
               });
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}





