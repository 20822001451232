import { tailwindConfig,hexToRGB } from "../utils/Utils";
export const chartData = {
  labels: [
    "04-01-2020",
    "05-01-2021",
    "02-02-2021",
    "03-03-2021",
    "04-01-2021",
    "05-01-2021",
    "06-01-2021",
    "07-01-2021",
    "08-01-2021",
    "09-01-2021",
    "10-01-2021",
    "11-01-2021",
    "12-01-2021",
    "01-01-2022",
    "02-01-2022",
    "03-05-2022",
    "04-05-2022",
    "05-05-2022",
    "06-01-2022",
    "07-01-2022",
    "08-01-2022",
    "09-01-2022",
    "10-01-2022",
    "11-01-2022",
    "12-01-2022",
    "01-01-2023",
  ], 
  // labels: [
  //   "29-04-2021",
  //   "27-03-2023",
  //   "26-05-2023",
  //   "27-05-2023",
  //   "28-05-2023",
  //   "29-05-2023",
    
  // ],
  datasets: [
    // Indigo line
    {
      
      // data: [
      //   732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391, 192,
      //   154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532,
      // ],
      data: [
        73, 61, 61, 50, 50, 50, 34, 34, 50, 34, 50, 61, 39, 19,
        15, 27, 19, 19, 12, 26, 34, 25, 42, 62, 47, 53,
      ],
      fill: true,
      backgroundColor: `rgba(${hexToRGB(
        tailwindConfig().theme.colors.blue[500]
      )}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
    // Gray line
    // {
    //   data: [
    //     532, 532, 532, 404, 404, 314, 314, 314, 314, 314, 234, 314, 234, 234,
    //     314, 314, 314, 388, 314, 202, 202, 202, 202, 314, 720, 642,
    //   ],
    //   borderColor: tailwindConfig().theme.colors.slate[300],
    //   borderWidth: 2,
    //   tension: 0,
    //   pointRadius: 0,
    //   pointHoverRadius: 3,
    //   pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
    //   clip: 20,
    // },
  ],
};
