import { getAxiosInstance } from "../Config";

//* get all users from database
export const fetchAllUsers = async () => {
    const axiosInstance = await getAxiosInstance();

    try {
        return axiosInstance
            .get("/user/getAllUsers")
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(`Error: ${response.data}`);
                }
                return response.data;
            })
            .catch((error) => {
                console.error(error.message);
                return error.message;
                // alert(error.message);
            });
    } catch (error) {
        console.error(error.message);
        return error.message;

    }
}


// * update user in database
export const updateUser = async ( data) => {
    const axiosInstance = await getAxiosInstance();
     
    try {
      
             return  axiosInstance
              .patch("/user/updateUser", data)
              .then((response) => {
                if (response.status !== 200) {
                  throw new Error(`Error: ${response.data}`);
                }
                return response.data;
              }).catch((error) => {
                console.error(error.message);
                return error.message;
                // alert(error.message);
              }
            );
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}



// * create new user in database
export const createUser = async ( data) => {
    console.log("data", data);
    const axiosInstance = await getAxiosInstance();
    try {
             return axiosInstance
               .post("/user/createUser", data)
               .then((response) => {
                 if (response.status !== 201) {
                   throw new Error(`Error: ${response.data}`);
                 }
                 return response.data;
               })
               .catch((error) => {
                 console.error(error.message);
                 return error.message;
                 // alert(error.message);
               });
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
};

// * delete user from database
export const deleteUser = async (ids) => {
    console.log("ids", ids);
    const axiosInstance = await getAxiosInstance();
    try {
             return axiosInstance
               .delete("/user/deleteUser", { data: { ids } })
               .then((response) => {
                 if (response.status !== 200) {
                   throw new Error(`Error: ${response.data}`);
                 }
                 return response.data;
               })
               .catch((error) => {
                 console.error(error.message);
                 return error.message;
                 // alert(error.message);
               });
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}
