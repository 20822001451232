import React from 'react'

const DoctorImage = () => {
  return (
            <div className='md:col-span-2 h-auto  min-h-max'>
                <div className="flex flex-col col-span-4 bg-white  rounded-sm  border-slate-200">
                    
                    <div className="grow p-3">
                        <div className="flex flex-col h-full">
                      <img src="/assets/doc.jpg" alt="doctor" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


export default DoctorImage