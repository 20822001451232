import { getAxiosInstance } from "../Config";
import axios from 'axios';



// * GET PATIENT COUNT DATA FROM DATABASE
export const getPatientCount = async (region) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;
  console.log("country", country);

  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/dashboardPatientCount", {
      params: { patientCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view patient count data");
      }
    });
};

    // * GET SMS COUNT DATA FROM DATABASE
export const getSmsCount = async (region) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;

  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/dashboardSmsCount", {
      params: { smsCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view sms count data");
      }
    });
};


//  * get enrollment count for bar chart
export const getEnrollmentCount = async (region = null) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;
  
  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/patientEnrollmentOverTime", {
      params: { enrollmentCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view enrollment count data");
      }
    });
};


    //*get patient count for specific site based on country
export const getSitePatientCount = async (region) => {

    var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;


    const axiosInstance = await getAxiosInstance();
    
    return axiosInstance
      .get("/dashboard/sitepatientcount", {
        params: {
          siteCountry: country,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error.message);
        if (error.message === "Request failed with status code 403") {
          alert("You are not authorized to view patient count data");
        }
      });
    }


  // * get reaminaing sms balance count -- Bangladesh
  export const getReaminaingSmsBalanceCount_Bangla = async () => {
    const body = {
      api_token: process.env.REACT_APP_BAN_SMS_API_KEY,
      sid: "TBPBULK",
    };
    let url = "https://smsplus.sslwireless.com/api/v3/balance";
    try {
      const response = await axios.post(url, body);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log("problem with request: " + error.message);
      return error.status;
    }
  };


   // * get reaminaing sms balance count -- Pakistan
  export const getReaminaingSmsBalanceCount_Pak = async () => {
    let records =[];
    const API_KEY = process.env.REACT_APP_PAK_SMS_API_KEY;
    let url = `https://api.veevotech.com/check_quota?hash=${API_KEY}`;
    try {
      const response = await axios.get(url);
      console.log("response: " + JSON.stringify(response));
      const lastRow = records.push(JSON.stringify(response.data.length - 1)); 
      return lastRow;
    } catch (error) {
      console.log("problem with request: " + error.message);
      return error.status;
    }
  };


   //*get patient count for specific site based on country
export const getSmsBalance = async (region) => {

  // var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;


  const axiosInstance = await getAxiosInstance();
  
  return axiosInstance
    .get("/dashboard/reaminingsmsinfo", {
      // params: {
      //   siteCountry: country,
      // },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view patient count data");
      }
    });
  }


  //* get site count active/inactive based on country
export const getSiteCount = async (region) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;

  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/siteCount", {
      params: { siteCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view site count data");
      }
    });
}

//* get total patient count for a site based on country
export const getSitePatientCountTotal = async (region) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;

  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/totalSitePatientCount", {
      params: { siteCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view site patient count data");
      }
    });
}

// get patient enrollment stats based on  country
export const getEnrollmentStats = async (region) => {
  var country = region == 2 ? "Pakistan" : region == 3 ? "Bangladesh" : null;

  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/dashboard/patientEnrollmentStatsByCountry", {
      params: { enrollmentCountry: country },
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
      if (error.message === "Request failed with status code 403") {
        alert("You are not authorized to view enrollment stats data");
      }
    });
}




