import React,{useState} from 'react'
import DataTableTemplate from '../Tables/DataTableTemplate';
import { CronMonitoringData } from '../../api/AdminPortalAPIs/monitoringLogs'

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

const CronJobsMonitoringData = () => {

  const columns = [
    {
      name: 'Job Time',
      selector: 'jobName',
      sortable: true,
    },
    {
      name: 'Last Executed',
      selector: 'lastRun',
      sortable: true,
      cell: (row) => formatDate(row.lastRun), // Format the date using the formatDate function
    },
    {
      name: 'Job Status',
      selector: row => row.status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.status === "Success",
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.status === "Failure",
          style: {
            backgroundColor: 'rgba(254, 60, 60, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',

            },

          },

        }]
    },
    {
      name: 'Error',
      // selector: 'errorMessage',
      selector: row => row.errorMessage || "none",
      sortable: true,
    },
    
   
  ];

    const [cronJobsData, setcronJobsData] = useState([]);
  React.useEffect(() => {
    CronMonitoringData().then((data) => {
      setcronJobsData(data);
      console.log(data);
    }).catch((err) => {
      console.error(err);
    });

  }, []);
  return (
    <div className='my-4'>
        {/* hi */}

      {cronJobsData && cronJobsData.length > 0 && (
        <DataTableTemplate columns={columns} data={cronJobsData}
          title={"Cron Jobs Monitoring"}
          isselectableRows={false}
        />

      )
      }
    </div>
  )
}

export default CronJobsMonitoringData