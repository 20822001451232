import React,{useState,useEffect} from 'react'
import StatsCard from './DashboardStatisticsCards/StatsCard';
import CountryChart from './DashboardStatisticsCards/CountryChart';
import SiteChart from './DashboardStatisticsCards/SiteChart';
import UserStats from './DashboardStatisticsCards/UserStats';
import PatientEnrolledOverTime from './DashboardStatisticsCards/PatientEnrolledOverTime';
import PatientEnrollmentStatsByCountry from '../Home/Common/PatientEnrollmentStatsByCountry';
import { getSmsBalance, getPatientCount, getSmsCount } from "../../api/Home/api";

const DashboardStatistics = () => {

  const [apiBalance, setapiBalance] = useState([]);

  // var country = Region?.Region == 2 ? 'Pakistan' : Region?.Region == 3 ? 'Bangladesh' : null;

  const [SmsCount, setSmsCount] = useState([]);
  
  const getSmsCountData = async () => {
    try {
      const response = await getSmsCount();
      console.log("smscount", response);
      setSmsCount(response[0]);
    } catch (error) {
      setSmsCount([]);
      console.log(error);
    }

  }


  const [patientCounts, setPatientCounts] = useState(null);

  const getPatientCountData = async()=>{
    try {
      const response = await getPatientCount();
      const patient_count = response[0];

      setPatientCounts({
        total: patient_count.total_count,
        active: patient_count.total_count - (patient_count.completed_count + patient_count.inactive_count),
        completed: patient_count.completed_count,
        inactive: patient_count.inactive_count,
      });

      console.log(patientCounts);
    } catch (error) {
      console.error('Error fetching patient counts: ', error);
    }
  }
  useEffect(() => {
    getPatientCountData();
    getSmsCountData();
   
  }, []); // Empty dependency array to run the effect once on component mount


  React.useEffect(() => {
    getSmsBalance().then((res) => {
      setapiBalance(res);
    }).catch((err) => {
      console.log("Error: " + err);

    })
  }, []);
  return (
    <div className="grid gap-6 grid-cols-12">
      <PatientEnrolledOverTime />
      {/* <StatsCard cardTitle={"Patient Registered Over Time"} cardValue={""} isChart={true}  /> */}
      {
        SmsCount ?
        <StatsCard cardTitle={"Total Sms"} SmsCount={SmsCount} smsCount={SmsCount} cardValue={null} isChart={false}  />
        :null
      }
      {
        apiBalance ?
          <StatsCard cardTitle={"Remaining SMS"} cardValue={null} cardData={apiBalance}    isChart={false}  />
          :null
      }
      {/* <OperationalCost /> */}
    
      <UserStats />
      {patientCounts !== null ? (
        <CountryChart patientCounts={patientCounts} />
      ) : null}
      {/* <SiteChart /> */}
      {/* <StatsCard cardTitle={"New Registration"} cardValue={"23,698"} isChart={false}  /> */}
      {/* <StatsCard cardTitle={"Operational Cost"} cardValue={"23,698 USD"} isChart={false}  /> */}
    </div>
  )
}

export default DashboardStatistics
