import axios from 'axios';
import { useContext } from 'react';
import { redirectToLogin, handleError } from "../utils/HandleErrors";
import useAuth from "../middleware/useAuth";
import { AuthContext } from '../Context/AuthProvider';
 
const CONFIG_OPTIONS = {
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `${localStorage.getItem("access_token")}`,
  },
};



        
// export const getAxiosInstance = () => {

//     const authAxiosInstance = axios.create({
//         baseURL: CONFIG_OPTIONS.baseURL,
//         headers: CONFIG_OPTIONS.headers,
//     });
//     authAxiosInstance.defaults.headers.common["Authorization"] = localStorage.getItem("access_token");
//   //   authAxiosInstance.interceptors.response.use(
//   //     function (response) {
//   //       return response;
//   //     }, error => {
//   // if (error.response.status === 401) {
//   //   return redirectToLogin();
//   // }
//   // return error;
//   //     }
//   //   );

//   //   return authAxiosInstance;

//     return authAxiosInstance.get("/auth/profile").then((response) => {
//          // eslint-disable-next-line eqeqeq
//          if (response.status == 401) {
//             // redirectToLogin();
//          }
//         return authAxiosInstance; 
//       })
//       .catch((error) => {
        
//         handleError(error.response.status);
//         console.error(error.message);
//         alert(error.message);
//       });
// }
export const getAxiosInstance = () => {
  const authAxiosInstance = axios.create({
    baseURL: CONFIG_OPTIONS.baseURL,
    headers: CONFIG_OPTIONS.headers,
    withCredentials: true,
  });

  // add a request interceptor to include the token in the Authorization header
  authAxiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });

  // authAxiosInstance.defaults.headers.common["Authorization"] = localStorage.getItem("access_token");

  // authAxiosInstance.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   }, error => {
  //     if (error.response.status === 401) {
  //       return redirectToLogin();
  //     }
  //     return error;
  //   }
  // );

  return authAxiosInstance;

  //  return authAxiosInstance
  //    .get("/auth/profile")
  //    .then((response) => {
  //      // eslint-disable-next-line eqeqeq
  //      if (response.status == 401) {
  //        localStorage.removeItem("access_token");
  //        localStorage.removeItem("user");
  //         window.location("http://localhost:3000/login");
  //        throw new Error("Unauthorized");
  //      }
  //      return authAxiosInstance;
  //    })
  //    .catch((error) => {
  //      if (error.response && error.response.status === 401) {
  //        localStorage.removeItem("access_token");
  //        localStorage.removeItem("user");
  //        throw new Error("Unauthorized");
  //      }
  //      handleError(error.response.status);
  //      console.error(error.message);
  //      alert(error.message);
  //    });
}

