import React from 'react'
import DataTable from 'react-data-table-component';
const DataTableTemplate = ({ columns, data, title, contextActions = null, handleRowSelected = null, toggledClearRows = false, setToggleClearRows, isselectableRows = true }) => {
    const MessageContent = ({data}) => {
        console.table(data.MessageText);
        return (
        <div className='px-5 py-2'>
             <div className='py-2'> 
                <h1 className='text-sm font-poppins font-semibold'>Message Content:</h1> 
                <p className='text-xs'>{data.MessageText}</p>
            </div> 
        </div>
        )
    }

    const hasExpandableRows = (data) => {
        return data && data.MessageText != null ? true : false;
    }
    const expandableRows = hasExpandableRows(data && data[0]);

    const customStyles = { rows: { style: { border: '1px solid #EEEEEE', } }, headCells: { style: { backgroundColor: '#EEEEEE', color: '#707070', fontSize: '14px', fontWeight: 'bold', }, } }
    return (
        <>
        {
                expandableRows ? <DataTable
                    title={title}
                    pagination
                    columns={columns}
                    data={data}
                    defaultSortFieldId={1}
                    paginationPerPage={5}
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    selectableRows highlightOnHover
                    customStyles={customStyles}
                    contextActions={contextActions}
                    onSelectedRowsChange={handleRowSelected}
                    expandableRows
                    expandableRowsComponent={MessageContent}
                    clearSelectedRows={toggledClearRows}
                />
                    : <DataTable
                        title={title}
                        pagination
                        columns={columns}
                        data={data}
                        defaultSortFieldId={1}
                        paginationPerPage={5}
                        fixedHeader
                        contextActions={contextActions}
                        fixedHeaderScrollHeight='600px'
                        // selectableRows highlightOnHover
                        selectableRows={isselectableRows} 
                        onSelectedRowsChange={handleRowSelected}
                        customStyles={customStyles}
                        clearSelectedRows={toggledClearRows}
                        // expandableRows={hasExpandableRows()}
                        // expandableRowsComponent={MessageContent}
                    />

                
        }
        </>
        
    );
}

export default DataTableTemplate