import React, { useState, useEffect, useMemo, useCallback } from 'react'
import DataTableTemplate from '../Tables/DataTableTemplate'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

const columns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
    },
    // {
    //     name: 'Patient Name',
    //     selector: row => row.Name,
    //     sortable: true,
    //     // cell: row => <a href={row.} target="_blank" rel="noopener noreferrer">{row.website}</a>
    //     // cell: row => <Link className='underline  text-blue-600' to={"/patient-Details/" + row.TBRegistrationNumber}>{row.Name}</Link>
    // },
    {
        name: 'Reg. Number',
        selector: row => "reg. number",
        cell: row => <p className='font-poppins font-bold  text-green-800' >{row.reg_id}</p>,
        // selector: row => row.TBRegistrationNumber,
        sortable: true,
    }, 
    {
        name: 'Phone Number',
        selector: row => "reg. number",
        cell: row => <p className='font-poppins font-bold  text-green-800' >{row.PhoneNumber}</p>,
        // selector: row => row.TBRegistrationNumber,
        sortable: true,
    },
    {
        name: 'Site Id',
        // selector: row => moment(row.ScreeningDate).format('DD-MM-YYYY'),
        // cell: row => <p className='font-poppins font-semibold  text-red-600' >{moment(row.ScreeningDate).format('DD-MM-YYYY')}</p>,
        cell: row => <p className='font-poppins font-semibold  text-red-600' >{row.Site_id}</p>,

        sortable: true,
    },
    // {
    //     name: 'Action',
    //     selector: 'Action',
    //     cell: row => <Link className='btn btn-sm rounded-lg bg-primary  text-white hover:bg-blue-600 float-right right-0' to={"/group-details/" + row.id}>Manage</Link>

    // },

];

const NonGroupMemberTable = ({ groupId, nonGroupMembers, submitAction, searchQuery, setSearchQuery }) => {

    const [formData, setformData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    // * Action context for selected rows
    const contextActions = useMemo(() => {
        return (
            <>
                <div className="flex justify-end gap-2 ">
                   
                    <button type="button" key="delete" onClick={() => handleAction('add', selectedRows)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                            
                        >
                            <path d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z" />
                        </svg>
                        Add to Group
                    </button>
                </div>
            </>
        );
    }, [selectedRows]);

    // * handle row selection (checkbox)
    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    // * handle actions on selected rows
    const handleAction = async (action, row) => {

        switch (action) {
            case 'edit':
                break;
            case 'add':
                // const confirm = window.confirm("Are you sure you want to delete this record?");
                // if (confirm) {
                    // extract ids of all selected row
                    console.log(row);
                    const ids = row.map((item) => item.id);
                    console.log(ids);
                submitAction('add', ids);


                    // toast.success("Message Template Deleted Successfully");

                    // create an array of objects with just the id property
                    // const idObjects = ids.map((id) => ({ id }));
                    // console.log(idObjects);
                    // delete selected rows
                    // await deleteData(currentTable, idObjects);
                    // await deleteData(currentTable, ids);
                    // fetchData(currentTable);
                    setToggleClearRows(!toggledClearRows);
                // }
                break;
            default:
                break;
        }
    };


   










  return (
      <div className="flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100 flex items-center">
              {/* <h2 className="font-poppins font-bold text-slate-800">Non Group Members</h2> */}
          </header>
          <div className="px-5 py-1 hidden">
              <div className="flex flex-wrap">
                  {/* Unique Visitors */}
                  <div className="flex items-center py-2">
                      <div className="mr-5">
                          <div className="flex items-center">
                              <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">24.7K</div>
                              <div className="text-sm font-poppins font-medium text-emerald-500">+49%</div>
                          </div>
                          <div className="text-sm text-slate-500">Unique Visitors</div>
                      </div>
                      <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
                  </div>
                  {/* Total Pageviews */}
                  <div className="flex items-center py-2">
                      <div className="mr-5">
                          <div className="flex items-center">
                              <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">56.9K</div>
                              <div className="text-sm font-poppins font-medium text-emerald-500">+7%</div>
                          </div>
                          <div className="text-sm text-slate-500">Total Pageviews</div>
                      </div>
                      <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
                  </div>
                  {/* Bounce Rate */}
                  <div className="flex items-center py-2">
                      <div className="mr-5">
                          <div className="flex items-center">
                              <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">54%</div>
                              <div className="text-sm font-poppins font-medium text-amber-500">-7%</div>
                          </div>
                          <div className="text-sm text-slate-500">Bounce Rate</div>
                      </div>
                      <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
                  </div>
                  {/* Visit Duration*/}
                  <div className="flex items-center">
                      <div>
                          <div className="flex items-center">
                              <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">2m 56s</div>
                              <div className="text-sm font-poppins font-medium text-amber-500">+7%</div>
                          </div>
                          <div className="text-sm text-slate-500">Visit Duration</div>
                      </div>
                  </div>
              </div>
          </div>
          {/* Chart built with Chart.js 3 */}
          <div className="grow">
            
                
                      <div className="overflow-x-auto ">

              <div class="relative float-right mr-1">
                  <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
                      value={searchQuery}
                      onChange={event => setSearchQuery(event.target.value)}
                      placeholder="Search..." />
                  {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
              </div>
              <DataTableTemplate columns={columns} data={nonGroupMembers}
                  title={"Non Group Members"}
                  contextActions={contextActions}
                  handleRowSelected={handleRowSelected}
                  toggledClearRows={toggledClearRows} setToggleClearRows={setToggleClearRows}
              />
                </div>


              {/* Change the height attribute to adjust the chart height */}
              {/* <LineChart data={chartData} width={800} height={300} /> */}
          </div>
      </div>
  )
}
export default NonGroupMemberTable