import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PageTitle from '../../Components/utils/PageTitle';
import { getMessageTemplates } from '../../api/MessageTemplate';
import { toast } from 'react-toastify';
import { GrAdd } from 'react-icons/gr';
import ModalLayout from '../../Components/Modals/ModalLayout';
import { addMessageTemplate, updateMessageTemplate, deleteMessageTemplate } from '../../api/MessageTemplate';
import DataTableTemplate from '../../Components/Tables/DataTableTemplate';
import MessageTemplateModalInputFields from '../../Components/Modals/MessageTemplateModalInputFields';

const MessageTemplate = () => {
  // set tab active on button click
  const [activeTab, setActiveTab] = useState(1);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [AddTemplateModalOpen, setAddTemplateModalOpen] = useState(false)
  // const [loaded, setLoaded] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');




  // * Action context for selected rows
  const contextActions = useMemo(() => {
    return (
      <>
        <div className="flex justify-end gap-2  ">
          {selectedRows.length < 2 ? 
          <button type="button" key="edit" onClick={(e) => { e.stopPropagation(); handleAction('edit', selectedRows) }} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M6.3 12.3l10-10a1 1 0 011.4 0l4 4a1 1 0 010 1.4l-10 10a1 1 0 01-.7.3H7a1 1 0 01-1-1v-4a1 1 0 01.3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 012 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h6a1 1 0 010 2H4v14h14v-6z" />
            </svg>
            Edit
          </button> : null
          }
          <button type="button" key="delete" onClick={() => handleAction('delete', selectedRows)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" />
            </svg>
            Delete
          </button>
        </div>
      </>
    );
  }, [selectedRows]);

  // * handle row selection (checkbox)
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  // * handle actions on selected rows
  const handleAction = async (action, row) => {

    switch (action) {
      case 'edit':
        console.log("open edit modal");
        console.log("selected row:", row[0]);
        setAddTemplateModalOpen(true);
        break;
      case 'delete':
        const confirm = window.confirm("Are you sure you want to delete this record?");
        if (confirm) {
          // extract ids of all selected row
          const ids = row.map((item) => item.id);
          console.log(ids);

          await deleteMessageTemplate(ids);
          getMessageTemplatesData();
          // toast.success("Message Template Deleted Successfully");
          
          // create an array of objects with just the id property
          // const idObjects = ids.map((id) => ({ id }));
          // console.log(idObjects);
          // delete selected rows
          // await deleteData(currentTable, idObjects);
          // await deleteData(currentTable, ids);
          // fetchData(currentTable);
          // setToggleClearRows(!toggledClearRows);
        }

        break;
      default:
        break;
    }
  };


  //* handle form submit

  const handleSubmit = async (data,isEdit) => {
    // event.preventDefault();
    if(isEdit){
      console.log("edit");
       await updateMessageTemplate(data).then((res) => {
        console.log(res);
        toast.success("Updated Message Template");
      }).catch((err) => {
        console.log(err);
        toast.error("Error in updating Message Template");
      });
      
    }
    else{
      console.log("add");
      console.log(data);
       await addMessageTemplate(data).then((res) => {
        console.log(res);
        toast.success("Added Message Template");
      }).catch((err) => {
        console.log(err);
        toast.error("Error in adding Message Template");
      });
    }
      setAddTemplateModalOpen(false);
      setSelectedRows([]);
      getMessageTemplatesData();
      setToggleClearRows(!toggledClearRows);
    
  };
  // Columns for Data Table
const columns = [
   
    {
      name: 'Day',
      selector: row => row.DayIndex.toString(),
      sortable: true,
    },
    {
    name: 'Message Sending Time * ',
      // selector: row => "Message # "+row.MessageIndex,
      selector: row => MessageIndexMapping(row.cronTime),
      sortable: true,
    },
    {
      name: 'Language',
      selector: row => row.Language,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.Language === "Urdu",
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.Language === "Bangla",
          style: {
            backgroundColor: 'rgba(254, 60, 60, 0.9)',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',

            },

          },

        }]
    },
    // {
    //   name: 'MessageText',
    //   selector: row => row.MessageText,
    //   sortable: true,
    // },


  ];

  //* make api call
  const getMessageTemplatesData = async () => {
    try {
      // setLoaded(false);
      const msgTemp = await getMessageTemplates(activeTab);
      console.log(msgTemp);
      if (msgTemp.length > 0) {
        setMessageTemplates(msgTemp);
        toast.success("Message Templates Loaded Successfully");
        // setLoaded(true);
      }
      else {
        toast.error("Nothing Found");
        setMessageTemplates([]);
      }

    } catch (error) {
      toast.error("Something went wrong");
    }
    finally {
      // setLoaded(true);
    }
  }
  useMemo(() => {

  document.title = "Message Portal";
  }, []);
  useEffect(() => {

     getMessageTemplatesData();
    console.log("useEffect called");
  }, [activeTab]);


  const MessageIndexMapping = (index) => {
    switch (index) {
      case 1:
        return "9:00 AM";
      case 2:
        return "12:00 PM";
      case 3:
        return "02:00 PM";
      case 4:
        return "05:00 PM";
      case 5:
        return "07:00 PM";
      case 6:
        return "07:30 PM";
      default:
        return "Not Found";
    }
  };

  //* search filter
  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return messageTemplates;
    }

    return messageTemplates.filter(row => {
        
      // check if the row key is cron time, language or message day . the rest of the fields are not needed for search
      // also check that for cron time we need to convert it according to MessageIndexMapping function
      return (
        MessageIndexMapping(row.cronTime).toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
        row.Language.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
        row.DayIndex.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
      );

    });
  }, [messageTemplates, searchQuery]);




  return (
    <div>
      <PageTitle title="Message Template" />
      {/* Tabs With Container */}

      <div>
        {/* Start */}
        {/* <div className="flex p-3 flex-col flex-wrap  justify-end  md:flex-row"> */}
        <ul className="flex flex-wrap -m-1">
          <li className="m-1">
            <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
              (activeTab === 1
                ? "bg-[#0F6CBD] text-white" : " bg-white text-slate-500")
            } onClick={e => {
              e.preventDefault();
              setActiveTab(1);
            }} >All</button>
          </li>
          <li className="m-1">
            <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
              (activeTab === 2
                ? "bg-[#0F6CBD] text-white"
                : " bg-white text-slate-500")
            } onClick={e => {
              e.preventDefault();
              setActiveTab(2);
            }}>Pakistan</button>
          </li>
          <li className="m-1">
            <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
              (activeTab === 3
              ? "bg-[#0F6CBD] text-white"
                : " bg-white text-slate-500")
            } onClick={e => {
              e.preventDefault();
              setActiveTab(3);
            }}>Bangladesh</button>
          </li>
        </ul>
        {/* </div> */}
        <span className="text-rose-700"> All time mentioned are according to GMT+5</span>
        {/* End */}

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Sync DB button */}
          <button className="btn bg-[#0F6CBD] hover:bg-indigo-600 text-white" onClick={(e) => { e.stopPropagation(); setAddTemplateModalOpen(true); }}>
            {/* <GrAdd className='text-white' size={20} /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20a10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16z" /></svg>

            <span className="hidden xs:block ml-2">Add Template</span>
          </button>

          <ModalLayout id="feedback-modal" modalOpen={AddTemplateModalOpen} setModalOpen={setAddTemplateModalOpen} title="Send Feedback">
            <div className="flex flex-col w-full">
              
              <MessageTemplateModalInputFields selectedRow={selectedRows} submitForm={handleSubmit} setEditModalOpen={setAddTemplateModalOpen} isEdit={selectedRows.length>0? true:false}/>
            </div>
          </ModalLayout>

        </div>
      </div>



      <div className='mt-4 p-5'>
        {/* Table */}
        <div className="overflow-x-auto">
          <div class="relative float-right">
            <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
              value={searchQuery}
              onChange={event => setSearchQuery(event.target.value.toString())}
              placeholder="Search..." />
            {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
          </div>
          <DataTableTemplate columns={columns} data={filteredData}
            title={"Message Templates"}
            contextActions={contextActions}
            handleRowSelected={handleRowSelected}
            toggledClearRows={toggledClearRows} setToggleClearRows={setToggleClearRows}
          />

        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default MessageTemplate;