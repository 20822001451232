import React,{useState} from 'react'
import moment from 'moment';
import {AiOutlineUser} from 'react-icons/ai';
import { HiPencilSquare } from 'react-icons/hi2';
import { getDateDifference } from '../../utils/Helper'
import ManagePatientStatus from '../PatientProfile/ManagePatientStatus';
import { updatePatientstatus } from '../../api/Patient';
import ModalLayout from '../Modals/ModalLayout';
import UpdatePatientDetailsModal from '../Modals/UpdatePatientDetailsModal'; 
// import moment from 'moment';
const PatientDetailsCard = ({ PatientInformation,updateInformation }) => {

    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [EditPatientData, setEditPatientData] = useState([]);



    const handleFormSubmit = async (data) => {
        console.log(data);
        // do something with the form data, like send it to a server or update state
        await updateInformation(data.status, data.phone_num)
            setEditModalOpen(false);

            // await updateData(table, data);
            // setSelectedRows([]);
            // fetchData(table);

        };

        React.useEffect(() => {
            const updateData = { "phone_num": PatientInformation?.PhoneNumber, "status": PatientInformation?.PatientStatus }
            setEditPatientData(updateData);
        }, [PatientInformation]);

    return (
        <div className='md:w-2/3 '>
            <div className='bg-primary rounded-t-lg shadow relative '>
                <div className='p-2 bg-primary text-center align-text-bottom rounded-lg'>
                    <div className='text-white flex justify-center'>
                        <AiOutlineUser className='mt-1 mr-1' width={40} />
                        <p className='font-poppins font-semibold text-lg'>{PatientInformation.reg_id}</p>
                        <HiPencilSquare size={28} onClick={(e) => { e.stopPropagation(); setEditModalOpen(true); }} className="inline-block mx-2 pt-1 text-[#fff]" />
                        {/* <p className='font-poppins font-semibold text-lg'>{PatientInformation.Country == "Pakistan" ? "PK-RWP-2023" : "BAN-DK-2023" }</p> */}
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-2 py-2 border rounded-b-lg bg-white h-5/6'>
                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Patient Status</div>
                        <div className='justify-self-center text-green-500 font-poppins font-bold dot'>
                            {PatientInformation.PatientStatus}
                            {/* <ManagePatientStatus currentStatus={PatientInformation.PatientStatus}  /> */}
                        </div>
                    </div>
                </div>
                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Contact No.</div>
                        <div className='justify-self-center'>{PatientInformation.PhoneNumber}</div>
                    </div>
                </div>
                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Patient Id</div>
                        <div className='justify-self-center'>{PatientInformation.TBRegistrationNumber}</div>
                    </div>
                </div>
                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Screening Date</div>
                        <div className='justify-self-center'>{PatientInformation.ScreeningDate}</div>
                    </div>
                </div>

                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Country</div>
                        <div className='justify-self-center'>{PatientInformation.Country}</div>
                    </div>
                </div>

                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Site Id</div>
                        <div className='justify-self-center'>{PatientInformation.Site_id ? PatientInformation.Site_id :'N/A' }</div>
                    </div>
                </div>

                {/* <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Smoking Status</div>
                        <div className='justify-self-center'>
                            {PatientInformation.DailySmoking === true ? 'Smoker' : 'Non-Smoker'}
                        </div>
                    </div>
                </div> */}
                {/* <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold '>Completed Days</div>
                        <div className='justify-self-center'>{getDateDifference(PatientInformation.ScreeningDate)}</div>
                    </div>
                </div> */}
                
                <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold '>Intervention Start Date</div>
                        <div className='justify-self-center'>{moment(PatientInformation.CreatedAt).format('YYYY-MM-DD')}</div>
                    </div>
                </div> 
                
                {<div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold '>Manually Updated</div>
                        <div className='justify-self-center'>{PatientInformation?.manuallyUpdated ? "Yes": "No"}</div>
                    </div>
                </div>}
                {/* <div className='relative'>
                    <div className='grid grid-cols-2 justify-around mt-2'>
                        <div className='justify-self-start ml-4 font-poppins font-semibold'>Smoking Duration</div>
                        <div className='justify-self-center'>
                            {PatientInformation.SmokingDurationYear > 0
                                ? PatientInformation.SmokingDurationYear + 'Y ' + PatientInformation.SmokingDurationMonth + 'M'
                                : PatientInformation.SmokingDurationMonth + ' Months'}
                        </div>
                    </div>
                </div> */}
            </div>
            <ModalLayout id="editPatientInfoModal" modalOpen={EditModalOpen} setModalOpen={setEditModalOpen} title="Edit Patient Info">
                <div className="flex flex-col w-full">
                    <UpdatePatientDetailsModal EditPatientData={EditPatientData} setEditPatientData={setEditPatientData}  isEdit={false} submitForm={handleFormSubmit} setModalOpen={setEditModalOpen} />
                </div>
            </ModalLayout>
        </div>

  )
}

export default PatientDetailsCard