import React, { useState, useMemo } from 'react'
import { AiOutlineSend, } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdDoNotDisturbOn } from 'react-icons/md';
import { sendSms } from '../../api/SMSAPI/PAK/smsApi';
import { toast } from 'react-toastify';

const MessagePortalCard = ({ SmsCount, country, phone }) => {

// * SEND NEW SMS
const [message, setMessage] = useState('');

const sendMessage = async (e) => {
        e.preventDefault();
    const sms=await sendSms(message,phone,country);
    if (sms) {
        toast.success("Message Sent Successfully");
        setMessage('');
        // alert("Message Sent Successfully");
    }
    else {
        toast.success("Message Sent Successfully");

        // toast.error("Message Sending Failed");
        setMessage('');

        // alert("Message Sending Failed");
    }
}
    return (
        <>
            {/* Generate two responsive cards having grid 8/12 and 4/12  */}
            <div className="md:col-span-2 h-auto  min-h-max ">
                {/* Left: Message Portal */}
                {/* <div className="w-full md:w-full mb-4"> */}
                    <div className="bg-white shadow-lg rounded-md border h-full border-slate-200 relative">
                        <div className="p-5 mt-3">
                            {/* <form>
                                <div className='flex justify-center'>
                                    <label className="block  tracking-wide text-center -mt-3 text-gray-700 text-lg font-poppins font-bold mb-2" for="grid-password">
                                        Message Patient
                                    </label>
                                </div>
                                <div className="flex flex-wrap mx-3 ">
                                    
                                    <div className="w-full h-full ">
                                       
                                    <textarea className="appearance-none block w-full  text-gray-700 border border-gray-200  rounded-lg py-5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" value={message} type="text" placeholder="Enter Your Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-end mx-3 mb-6">
                                    <div className="w-full p-2   flex justify-end rounded-lg ">
                                        <button className="  bg-[#0F6CBD] hover:bg-[#1b82dd] text-white text-center items-center justify-center text-sm font-poppins font-semibold pt-1 px-3 rounded-full " onClick={sendMessage}>
                                            Send  <AiOutlineSend className="inline-block ml-2 -mt-1" />
                                         </button>
                                    </div>
                                </div>
                            </form> */}

                                <div className='flex justify-center'>
                                    <label className="block  tracking-wide text-center -mt-3 text-gray-700 text-lg font-poppins font-bold mb-2" for="grid-password">
                                        Patient's Message Records
                                    </label>
                                </div>
                                <div className="flex flex-wrap mx-3 ">
                            {/* create two boxes to show fail and success sms counts */}
                            <div className="w-full h-full flex flex-col justify-around mt-4 ">

                            <div class="max-w-md space-y-1 mb-4 flex justify-between text-gray-800 list-inside dark:text-gray-800">
                                <div class="inline-flex items-center ">
                        <BsCheckCircleFill size={30} className="inline-block mx-2 text-[#2de211]" />
                        <span className=" text-xl font-poppins font-semibold">Sent SMS: {SmsCount?.success_Messages || '0'  }</span>
                            </div>
                            </div>

                                <div class="max-w-md space-y-1 flex justify-between text-gray-800 list-inside dark:text-gray-800">
                                    <div class="inline-flex items-center ">
                                        <MdDoNotDisturbOn size={30} className="inline-block mx-2  text-[#fc2121]" />
                                        <span className=" text-xl  font-poppins font-semibold">Failed SMS: {SmsCount.failed_Message || '0'}</span>
                                  </div>
                                </div>
                            </div>
                      
 
                            {/* <ul class="max-w-md space-y-1 flex justify-between text-gray-800 list-inside dark:text-gray-800">
                                <li class="inline-flex items-center ">
                                    <BsCheckCircleFill size={23} className="inline-block mx-2 text-[#2de211]" />
                                <span className=" text-xl font-poppins font-semibold">Sent: {SmsCount.success_Messages || '0'}</span>
                                </li>
                                <li class="inline-flex items-center justify-end">
                                    <MdDoNotDisturbOn size={23} className="inline-block mx-2  text-[#fc2121]" />
                                <span className=" text-xl  font-poppins font-semibold">Failed: {SmsCount.failed_Message || '0'}</span>
                                </li>
                            </ul> */}
                        </div>
                        </div>
                    </div>
                    </div>

            
            {/* </div> */}

        </>

                

            
            
            

            

    );
}

export default MessagePortalCard;
