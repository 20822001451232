import React from 'react'
import PdfViewer from '../../Components/PdfViewer'
import { PDFDownloader } from "../../utils/pdfDownload"
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const UserManual = () => {
  return (
    <div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl="/assets/user_manual.pdf" onOpenError={error => console.error(error)} />
          </Worker>
    </div>
  )
}

export default UserManual