/* eslint-disable eqeqeq */
/*
* THIS API IS USED FOR BOTH PAK AND BANGLADESH SMS SENDING ON CONDITIONAL BASIS
 

*/

import axios from 'axios';


// const APIKey = process.env.REACT_APP_PAK_SMS_API_KEY;
// const receiver = '923235005048';
// const sender = '8583';
// const textmessage = 'Test SMS from VT API';

// const options = {
//   baseURL: process.env.REACT_APP_PAK_SMS_API_URL,
//   timeout: 30000,
//   params: {
//     hash: APIKey,
//     receivenum: receiver,
//     sendernum: encodeURIComponent(sender),
//     textmessage: encodeURIComponent(textmessage)
//   }
// };

// axios.get('/sendsms', options)
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(error => {
//     console.log(error);
//   });

/**
 * @desc send sms to a single number
 * @param {string} sms -sms text
 * @return response
 */

export const sendSms = async (sms,phone, country) => {
    
      switch (country) {
        case "Pakistan":
          try {
        
         const res = await Promise.all([
          //  sendSMSusingJazz(sms, phone),
            sendSMSusingVeevoTech(sms, phone),
         ]);

         if (res.status == "ACCEPTED") {
           console.log("Message sent successfully");
           return true;
         } else {
           console.log("Message failed to send");
           return false;
         }
        } catch (error) {
          console.log(error);
          return false;
        }
        
        case "Bangladesh":
          try {
             const res = await Promise.all([
               //  sendSMSusingJazz(sms, phone),
               sendSMSusingSSLWireless(sms, phone),
             ]);

             if (res.status == "SUCCESS") {
               console.log("Message sent successfully");
               return true;
             } else {
               console.log("Message failed to send");
               return false;
             }
          } catch (error) {
            console.log(error);
            return false;
          }
          
        default:
          break;
      }

    }


//* sms api implementation using jazz sms api
const sendSMSusingJazz = async (sms,phone_num) => {
  console.log("sendSMSusingJazz",sms,phone_num);
  const formattedURL = `https://connect.jazzcmt.com/sendsms_url.html?Username=03015659704&Password=Jazz@123&From=Biz%20Demo&To=${phone_num}&Message=${sms}`;
    console.log(formattedURL);
  axios
    .get(formattedURL)
    .then((response) => {
      console.log(response.data);
      console.log("Status: " + response.status);
      return response.status;
    })
    .catch((error) => {
      console.log(error);
      return error.status;
    });

  
}


// var APIKey = '6aa19b26151b66c20a34c4c09a3259383eb3a3b';

//* sms api implementation using VeevoTech sms api

export const sendSMSusingVeevoTech = async (sms, phone_num) => {
  var APIKey = process.env.REACT_APP_PAK_SMS_API_KEY;
  var receiver = phone_num.toString();
  var sender = "8583";
  var textmessage = sms.toString();

  var url = `https://api.veevotech.com/sendsms?hash=${APIKey}&receivenum=${receiver}&sendernum=${encodeURIComponent(
    sender
  )}&textmessage=${encodeURIComponent(textmessage)}`;

  try {
    const response = await axios.get(url, { timeout: 30000 });
    console.log("STATUS: " + response.status);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("problem with request: " + error.message);
    return error.status;
  }
};

export const sendSMSusingSSLWireless = async (sms, phoneNum) => {
  const url = "https://smsplus.sslwireless.com/api/v3/send-sms";
  const currentTime = Date.now().toString();
  const body = {
    api_token: "nj9suxrp-wujtffyu-41bxym8z-ldyhgb6k-rrmatbac",
    sid: "ARKNONBULK",
    msisdn: phoneNum,
    sms: sms,
    csms_id: currentTime,
  };

  try {
    const response = await axios.post(url, body);
    console.log("STATUS: " + response.status);
    console.log("STATUS_CODE: " + response.status_code);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("problem with request: " + error.message);
    return error.status;
  }
};