import { getAxiosInstance } from "./Config";

//* GET PATIENT DATA FROM DATABASE
export const getPatients = async () => {
    const axiosInstance = await getAxiosInstance();
    console.log(axiosInstance);
    return axiosInstance
      .get("/patient/patientTableData")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error.message);
        // alert(error.message);
        return error;
      });
    }

 
  // * GET PATIENT DETAILS FROM DATABASE USING REG-ID
  export const getPatientDetails = async (regId) => {
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .get(`/patient/patientDetails/${regId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        // alert(error.message);
        // return error;

      });
  }

  //* GET SMS COUNT FROM DATABASE
  export const getSmsCountByPatientId = async (patientId) => {
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .get(`/patient/smsCount/${patientId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  }


  // * get patient notes by patient id
  export const getPatientNotesByPatientId = async (patientId) => {
    console.log(patientId);
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .get(`/patient/patientNotes/${patientId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.error(error.message);
          return error;

        // alert(error.message);
      });
  }

  // * add patient notes by patient id
  export const addPatientNotesByPatientId = async (patientId, notes) => {
    console.log(patientId, notes);
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .post(`/patient/addpatientNotes/${patientId}`, { notes })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  }

  //* delete a patient Note
  export const deletePatientNote = async (noteId) => {
    console.log(noteId);
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .delete(`/patient/deletePatientNote/${noteId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  }

  // * update patient status based on ID

  export const updatePatientstatus = async (status, phoneNum, patientId) => {
    // Create an empty data object
    const data = {};
  
    // Add the "PatientStatus" property if status is not empty or undefined
    if (status !== undefined && status !== null) {
      data.PatientStatus = status;
    }
  
    // Add the "PhoneNumber" property if phoneNum is not empty or undefined
    if (phoneNum !== undefined && phoneNum !== null) {
      data.PhoneNumber = phoneNum;
    }
  
    // Check if the data object is empty, and if it is, do not make the request
    if (Object.keys(data).length === 0) {
      console.warn("No data to send to the server.");
      return null;
    }
  
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .patch(`/patient/updatePatientStatus/${patientId}`, data)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  };
  

  // * reset manul update flag

  export const resetManualUpdate = async (patientId) => {
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .patch(`/patient/resetManaulUpdate/${patientId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  };