import React from 'react'
import {getSitePatientCountTotal} from '../../../api/Home/api'

const SiteBasedPatientCount = ({Region}) => {
    // site based patient count
    const [DashboardSitebasedPatientCount, setDashboardSitebasedPatientCount] = React.useState([]);

    const getSitebasedPatientCountData = async () => {
        const res = await getSitePatientCountTotal(Region);
        if (res) {
            setDashboardSitebasedPatientCount(res);
        }
    };

    React.useEffect(() => {
        getSitebasedPatientCountData();
    }, [Region]);
  return (
    <>
    <div className="item drop-shadow-xl">
        <div className="flex flex-col flex=1  border-gray-500 items-center justify-center p-3 text-start bg-white border rounded-2xl ">
            <div className="flex items-center   justify-self-stretch space-x-3">

                <div className=" flex flex-wrap justify-center items-end border border-gray-400 rounded-lg p-2 w-12 h-12 text-2xl text-gray-800" >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32"><path fill="#1f2937" d="M20 10a4 4 0 1 1-8 0a4 4 0 0 1 8 0M10 24a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2zM5 5.5A3.5 3.5 0 0 1 8.5 2h15A3.5 3.5 0 0 1 27 5.5v21a3.5 3.5 0 0 1-3.5 3.5h-15A3.5 3.5 0 0 1 5 26.5zM8.5 4A1.5 1.5 0 0 0 7 5.5V20h3v-3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v3h3V5.5A1.5 1.5 0 0 0 23.5 4zM7 26.5A1.5 1.5 0 0 0 8.5 28h15a1.5 1.5 0 0 0 1.5-1.5V22H7z"/></svg>
                  </div>
                 <div className="space-y-0.5 color-[#081a51] font-poppins font-lg  ml-2  text-left text-xl">
                    <div> Sites Patient Count</div>
                    <div className="text-md  font-poppins font-semibold text-gray-800 ">{ parseInt(DashboardSitebasedPatientCount[0]?.active_count) +  parseInt(DashboardSitebasedPatientCount[0]?.inactive_count || 0)  }</div>
                    {/* <div className="text-md  font-poppins font-semibold text-gray-800 ">{ parseInt(DashboardSitebasedPatientCount[0]?.total_count) || 0  }</div> */}
                </div>
            </div>
            <div className=" pr-3 mb-2 mt-3 ml-8  justify-self-center  text-gray-500 lg:mb-3 ">
                <h3 className="text-base  place-items-start font-poppins font-light text-gray-900 ">
                    Intervention: {parseInt(DashboardSitebasedPatientCount[0]?.active_count) || 0}
                </h3>
                <h3 className="text-base  place-items-center font-poppins font-light text-gray-900 ">
                    Control: {parseInt(DashboardSitebasedPatientCount[0]?.inactive_count) || 0}
                </h3>
            </div>

        </div>
    </div>
</>
  )
}

export default SiteBasedPatientCount