import React, {useState,useEffect} from 'react'
import { getSmsCount } from '../../../api/Home/api';
const SmsCountCard = ({Region}) => {

    // var country = Region?.Region == 2 ? 'Pakistan' : Region?.Region == 3 ? 'Bangladesh' : null;

    const [DashboardSmsCount, setDashboardSmsCount] = useState([]);
    const getSmsCountData = async () => {
        try {
            const response = await getSmsCount(Region);
            console.log("smscount",response);
            setDashboardSmsCount(response);
        } catch (error) {
            setDashboardSmsCount([]);
            console.log(error);
        }
        
    }
    useEffect(() => {
        getSmsCountData();
    }, [Region])
  return (
      <>
          <div className="item drop-shadow-xl">
              <div className="flex flex-col flex=1  border-gray-500 items-center justify-center p-3 text-start bg-white border rounded-2xl ">
                  <div className="flex items-center   justify-self-stretch space-x-3">

                      <div className=" flex flex-wrap justify-center items-end border border-gray-400 rounded-lg p-2 w-12 h-12 text-2xl text-gray-800 " >
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#081a51" d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-3 12H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" /></svg>          </div>
                      <div className="space-y-0.5 color-[#081a51] font-poppins font-lg  ml-2  text-left text-xl">
                          <div> Total SMS Sent</div>
                          <div className="text-md  font-poppins font-semibold text-gray-800 ">{ parseInt(DashboardSmsCount[0]?.success_count) +  parseInt(DashboardSmsCount[0]?.failed_count || 0)  }</div>
                      </div>
                  </div>
                  <div className=" pr-3 mb-2 mt-3 ml-8  justify-self-center  text-gray-500 lg:mb-3 ">
                      <h3 className="text-base  place-items-start font-poppins font-light text-gray-900 ">
                          Sms Delivered: {parseInt(DashboardSmsCount[0]?.success_count) || 0}
                      </h3>
                      <h3 className="text-base  place-items-center font-poppins font-light text-gray-900 ">
                          Sms Failed: {parseInt(DashboardSmsCount[0]?.failed_count) || 0}
                      </h3>
                  </div>

              </div>
          </div>
      </>
  )
}

export default SmsCountCard