import React from 'react'

import CircularProgressBar from '../../utils/circularProgressBar'
import { getDateDifference } from '../../utils/Helper'
const PatienDayCountChart = ({date}) => {
    
  return (
      <div className='md:col-span-2 h-auto  min-h-max'>
          <div className="flex flex-col col-span-4 justify-center bg-white shadow-lg rounded-sm border border-slate-200">
              <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-poppins font-semibold text-lg text-center text-slate-800">Program Progress</h2>
              </header>
              <div className="grow p-3 flex justify-center">
                  <div className="flex flex-col justify-center h-full">
                      {/* Card content */}
                      <div className="grow ml-6">
                          <CircularProgressBar selectedValue={getDateDifference(date)<189?getDateDifference(date):188}
                              maxValue={188}
                              radius={100}
                              activeStrokeColor='#0f2f4f'
                              withGradient
                              label='Day'
                              strokeWidth={10}
                              />
                      </div>
              </div>
          </div>
      </div>   
      </div>   
  )
}

export default PatienDayCountChart