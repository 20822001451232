import  { useState  } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setAuthenticated(false);
      return;
    }

    const decodedToken = jwtDecode(accessToken);
    const expiry = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();

    if (expiry < currentTime) {
      setAuthenticated(false);
      localStorage.removeItem("accessToken");
      navigate("/login");
    } else {
      setAuthenticated(true);
    }

  return authenticated;
}

export default useAuth;
