// import { getAxiosInstance } from "./Config";
import {
  fetchAllUsers,
  fetchAllSites,
  fetchAllAPIs,
  updateUser,
  updateSite,
  updateAPI,
  createUser,
  createSite,
  createAPI,
  deleteUser,
  deleteSite,
  deleteAPI,
} from "./AdminPortalAPIs/index";
//* get all users from database
// export const fetchAllData = async (activeTable="Users") => {
//     const axiosInstance = await getAxiosInstance();

//     try {
//       switch (activeTable) {
//           case "Users":
//                 return axiosInstance
//                   .get("/user/getAllUsers")
//                   .then((response) => {
//                     if (response.status !== 200) {
//                       throw new Error(`Error: ${response.data}`);
//                     }
//                     return response.data;
//                   })
//                   .catch((error) => {
                   
//                     console.error(error.message);
//                     return error.message;
//                     // alert(error.message);
                    
//                   });
                
//           case "Sites":
//                   return axiosInstance
//                     .get("/site/getAllSites")
//                     .then((response) => {
//                       if (response.status !== 200) {
//                         throw new Error(`Error: ${response.data}`);
//                       }
//                       return response.data;
//                     })
//                     .catch((error) => {
//                       console.error(error.message);
//                       return error.message;
//                     //   alert(error.message);
//                     });
               
//           case "APIs":
//                      return axiosInstance
//                        .get("/api/getAllAPIs")
//                        .then((response) => {
//                          if (response.status !== 200) {
//                            throw new Error(`Error: ${response.data}`);
//                          }
//                          return response.data;
//                        })
//                        .catch((error) => {
//                          console.error(error.message);
//                          return error.message;
//                         //  alert(error.message);
//                        });
//           default:
//                   break;
//       }
      
//     } catch (error) {
//         console.error(error.message);
//         return error.message;
//         // alert(error.message);
        
//     }
    
   
// }

// * get all data based on table name
export const fetchAllData = async (activeTable = "Users") => {

  try {
    switch (activeTable) {
      case "Users":
        return await fetchAllUsers();

      case "Sites":
        return await fetchAllSites();

      case "APIs":
        return await fetchAllAPIs();
      default:

        break;
    }
  } catch (error) {
    console.error(error.message);
    return error.message;
    // alert(error.message);
  }
};

// * update data based on table name
export const updateData = async (activeTable, data) => {
     console.log("update data:", data);
    try {
        switch (activeTable) {
          case "Users":

            return await updateUser(data);
          case "Sites":
            return await updateSite(data);
          case "APIs":
            return await updateAPI(data);
          default:
            break;
        }
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}


// * create data based on table name
export const createData = async (activeTable, data) => {
     
    try {
        switch (activeTable) {
          case "Users":
            return await createUser(data);
          case "Sites":
            return await createSite(data);
          case "APIs":
            return await createAPI(data);
          default:
            break;
        }
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}

// * delete data based on table name
export const deleteData = async (activeTable, data) => {
     
    try {
        switch (activeTable) {
          case "Users":
            console.log("delete user list:", data);
            return await deleteUser(data);
            case "Sites":
              console.log("delete site list:", data);
              return await deleteSite(data);
          case "APIs":
            return await deleteAPI(data);
          default:
            break;
        }
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}