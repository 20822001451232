import React from 'react'
import PatientCountCards from './Common/PatientCountCards'
import SmsCountCard from './Common/SmsCountCard'
import OperationalCost from './Common/OperationalCost';
import SiteCountCard from './Common/SiteCountCard';
import SiteBasedPatientCount from './Common/SiteBasedPatientCount';
import PatientEnrollmentStatsByCountry from './Common/PatientEnrollmentStatsByCountry';




const DetailsCard = ({ Region }) => {

  return (
    <div>
          <div className="grid mb-8 mt-3 gap-10 border-gray-400 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3">
        <PatientCountCards Region={Region} />
        <SmsCountCard Region={Region} />
        <OperationalCost Region={Region} />
          </div>

          <div className="grid mb-8 mt-3 gap-10 border-gray-400 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3">
        <SiteCountCard Region={Region} />
        <SiteBasedPatientCount Region={Region} />
        <PatientEnrollmentStatsByCountry Region={Region} />
          </div>
          
    </div>
  )
}

export default DetailsCard
