import React, { useEffect, useState } from 'react';
import { tailwindConfig } from '../../utils/Utils';
import PieChart from '../../../charts/PieChart';
import { getPatientCount } from '../../../api/Home/api';

const CountryChart = ({ patientCounts }) => {
  
    const chartData = {
        labels: [
            `Active: ${patientCounts.active}`,
            `Completed: ${patientCounts.completed}`,
            `Inactive: ${patientCounts.inactive}`,
        ],
         datasets: [
            {
                label: 'Sessions By Device',
                data: [patientCounts.active, patientCounts.completed, patientCounts.inactive],
                backgroundColor: [
                    tailwindConfig().theme.colors.indigo[600],
                    tailwindConfig().theme.colors.sky[600],
                    tailwindConfig().theme.colors.indigo[800],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().theme.colors.indigo[600],
                    tailwindConfig().theme.colors.sky[500],
                    tailwindConfig().theme.colors.indigo[900],
                ],
                hoverBorderColor: tailwindConfig().theme.colors.white,
            },
        ],
    };

    return (
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
            <header className="px-5 py-4 border-b border-slate-100">
                <h2 className="font-semibold text-slate-800">Total Patients: {patientCounts.total}</h2>
            </header>
            
            <PieChart data={chartData} width={389} height={260} />
        </div>
    );
};

export default CountryChart;
