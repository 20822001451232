import { Navigate } from "react-router-dom";
import { useContext,useEffect } from "react";
import Layout from "../Partials/Layout";
import { AuthContext } from "../Context/AuthProvider";
import {isTokenExpired} from "./isTokenExpired";
const PrivateRoutes = ({ component: Component, ...rest }) => {
  
   const token = localStorage.getItem("access_token");

    useEffect(() => {
      if (!token || isTokenExpired(token)) {
        window.location.href = "/login";
        console.log("token expired");
      }
    }, [token]);
  // let auth = localStorage.getItem("access_token") ? true : false;
  // const { user } = useContext(AuthContext);
  // console.log(user);

   return token && !isTokenExpired(token) ? (
     <Layout {...rest} />
   ) : (
     <Navigate to="/login" />
   );
  // if(user){
  //   return  <Layout />
  // }
  // else{
  //   return <Navigate to="/login" />
  // }
  // return auth ? <Layout /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
