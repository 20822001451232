import jwt_decode from "jwt-decode";

export function isTokenExpired(token) {
  const decodedToken = jwt_decode(token);

  if (!decodedToken) {
    return true;
  }

  const currentDate = new Date().getTime() / 1000;

  if (decodedToken.exp < currentDate) {
    return true;
  }

  return false;
}
