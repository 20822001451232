import React,{useState}from 'react'

const GroupMemberCards = ({ groupId, GroupMembers, submitAction }) => {
    console.log("GroupMembers",GroupMembers);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        setSelectedCheckboxes(prevState => {
            if (isChecked) {
                return [...prevState,value];
            } else {
                return prevState.filter(item => item !== value);
            }
        });
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        console.log(selectedCheckboxes);
        submitAction('remove', selectedCheckboxes);
        setSelectedCheckboxes([]);
    }




  return (
      <div className="flex flex-col col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
              <h2 className="font-poppins font-semibold text-slate-800">Active Users Right Now</h2>
          </header>
          {/* Card content */}
          <div className="flex flex-col h-full">
              {/* Live visitors number */}
              <div className="px-5 py-3">
                <div className='flex justify-end items-end'>
                    {
                        selectedCheckboxes.length > 0 ?
                    <button className='bg-blue-500 hover:bg-blue-700 btn-sm text-white font-poppins font-bold py-2 px-4 rounded' onClick={handleSubmit}>remove</button>
                    : null
                    }
                </div>
                  <div className="flex items-center">
                      {/* Red dot */}
                      <div className="relative flex items-center justify-center w-4 h-4 rounded-full bg-rose-100 mr-3" aria-hidden="true">
                          <div className="absolute w-1.5 h-1.5 rounded-full bg-rose-500"></div>
                      </div>
                      {/* Vistors number */}
                      <div>
                          {/* <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">{GroupMembers[0]?.totalMembers}</div> */}
                          <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">{GroupMembers.length}</div>
                          <div className="text-sm text-slate-500">Group Members</div>
                      </div>
                  </div>
              </div>

              {/* Chart built with Chart.js 3 */}
              <div >
                  {/* Change the height attribute to adjust the chart height */}
                  {/* <LineChart data={chartData} width={389} height={70} /> */}
              </div>

              {/* Table */}
              <div className="grow px-5 pt-3 pb-1">
                  <div className="overflow-x-auto">
                      <table className="table-auto w-full">
                          {/* Table header */}
                          <thead className="text-xs uppercase text-slate-400">
                              <tr>
                                  <th className="py-2">
                                      <div className="font-poppins font-semibold text-left">Patient</div>
                                  </th>
                                  <th className="py-2">
                                      <div className="font-poppins font-semibold text-right">Status</div>
                                  </th>
                              </tr>
                          </thead>
                          {/* Table body */}
                          <tbody className="text-sm divide-y divide-slate-100">
                              {/* Row */}

                              {
                                  GroupMembers && GroupMembers.map((member, index) => {
                                        return (
                                            <tr key={index} className="px-2">
                                                <td className="py-2">
                                                    <span className="inline-flex items-center mr-2">
                                                        <input type='checkbox' 
                                                            className='form-checkbox h-5 w-5 text-blue-500'
                                                        value={member.id}
                                                        name='member'
                                                            onChange={handleCheckboxChange}
                                                            // checked={selectedCheckboxes.includes(member.id)}
                                                            {...(selectedCheckboxes.length === 0 && { checked: false })}
                                                        />
                                                        {/* <label htmlFor="myCheckbox" className="ml-2 text-gray-700">{member.Name}</label> */}
                                                        <label htmlFor="myCheckbox" className="ml-2 text-gray-700">{member.reg_id}</label>
                                                        {/* <div className="ml-2">{member.Name}</div> */}
                                                    </span>
                                                </td>
                                                <td className="py-2">
                                                    { member.PatientStatus === 'Active' ?
                                                    <div className="font-poppins font-bold text-right text-blue-800">{member.PatientStatus}</div>
                                                    : member.PatientStatus === 'Inactive' ?
                                                    <div className="font-poppins font-bold text-right text-red-800">{member.PatientStatus}</div>
                                                    : member.PatientStatus === 'Completed' ?
                                                    <div className="font-poppins font-bold text-right text-green-800">{member.PatientStatus}</div>
                                                    : null
                                                }
                                                </td>
                                            </tr>
                                        )
                                    })
                              }
                            
                          </tbody>
                      </table>
                  </div>
              </div>

              {/* Card footer */}
              <div className="text-right px-5 pb-4">
                  {/* <Link className="text-sm font-poppins font-medium text-indigo-500 hover:text-indigo-600" to="#0">Real-Time Report -&gt;</Link> */}
              </div>
          </div>
      </div>
  )
}

export default GroupMemberCards