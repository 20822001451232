import { getAxiosInstance } from "../Config";

// get all failed messages logs from database
export const fetchAllFailedMessagesLogs = async () => {
    const axiosInstance = await getAxiosInstance();
    try {
        return axiosInstance
          .get("/monitoring/getAllFailedMessages")
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: ${response.data}`);
            }
            return response.data;
          })
          .catch((error) => {
            console.error(error.message);
            return error.message;
          });
    } catch (error) {
        console.error(error.message);
        return error.message;
    }
    }

  // re-attempt failed message
  export const reAttemptFailedMessage = async (row) => {
    const axiosInstance = await getAxiosInstance();
    try {
      return axiosInstance
        .post("/monitoring/reAttemptFailedMessages", row)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Error: ${response.data}`);
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          return error.message;
        });
    } catch (error) {
      console.error(error.message);
      return error.message;
    }
  }


  // get sms count based on country
  export const fetchSMSCount = async () => {
    const axiosInstance = await getAxiosInstance();
    try {
      return axiosInstance
        .get(`/monitoring/FailedSmsMonitoringCount`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Error: ${response.data}`);
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          return error.message;
        });
    } catch (error) {
      console.error(error.message);
      return error.message;
    }
  }

  export const CronMonitoringData = async()=>{
    const axiosInstance = await getAxiosInstance();

    try {
      return axiosInstance
        .get(`/monitoring/cronJobsMonitor`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Error: ${response.data}`);
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          return error.message;
        });
    } catch (error) {
      console.error(error.message);
      return error.message;
    }
    
  } 


  export const monitoring_remainingSmsSummary = async()=>{
    const axiosInstance = await getAxiosInstance();

    try {
      return axiosInstance
        .get(`/monitoring/smsSummary`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Error: ${response.data}`);
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error.message);
          return error.message;
        });
    } catch (error) {
      console.error(error.message);
      return error.message;
    }
    
  } 
