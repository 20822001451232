import { useState,createContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

    const handleLogin = (userData) => {
      setUser(userData);
      console.log(userData);
    };

    const handleLogout = () => {
      console.log("handleLogout called");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      setUser(null);
      window.location.href = "/login";
    };

  return (
    <AuthContext.Provider value={{ user, handleLogin, handleLogout,setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// export default AuthProvider;