import { getAxiosInstance } from "../Config";

//* get all api from database
export const fetchAllAPIs = async () => {
  const axiosInstance = await getAxiosInstance();
  try {
    return axiosInstance
      .get("/smsSubscription/getsmssubscriptionstats")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error.message);
        return error.message;
      });
  } catch (error) {
    console.error(error.message);
    return error.message;
  }
};

// * update api in database
export const updateAPI = async (data) => {
    const axiosInstance = await getAxiosInstance();
    
    try {
        return axiosInstance
          .patch("/smsSubscription", data)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: ${response.data}`);
            }
            return response.data;
          })
          .catch((error) => {
            console.error(error.message);
            return error.message;
            // alert(error.message);
          });
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
    }
    };

// * create new sms api subscription in database
export const createAPI = async (data) => {
    const axiosInstance = await getAxiosInstance();
    
    try {
        return axiosInstance
          .post("/smsSubscription/createsmssubscriptionstats", data)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: ${response.data}`);
            }
            return response.data;
          })
          .catch((error) => {
            console.error(error.message);
            return error.message;
            // alert(error.message);
          });
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
    }
    };
    
// * delete api from database
export const deleteAPI = async (data) => {
    const axiosInstance = await getAxiosInstance();
    
    try {
        return axiosInstance
        .delete("/deleteAPI", data)
        .then((response) => {
            if (response.status !== 200) {
            throw new Error(`Error: ${response.data}`);
            }
            return response.data;
        })
        .catch((error) => {
            console.error(error.message);
            return error.message;
            // alert(error.message);
        });
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
    }
    };
