import React from 'react'
import {getSiteCount} from '../../../api/Home/api'

const SiteCountCard = ({Region}) => {
    const [DashboardSiteCount, setDashboardSiteCount] = React.useState([]);

    const getSiteCountData = async () => {
        const res = await getSiteCount(Region);
        if (res) {
            setDashboardSiteCount(res);
        }
    };

    React.useEffect(() => {
        getSiteCountData();
    }, [Region]);
  return (
    <>
    <div className="item drop-shadow-xl">
        <div className="flex flex-col flex=1  border-gray-500 items-center justify-center p-3 text-start bg-white border rounded-2xl ">
            <div className="flex items-center   justify-self-stretch space-x-3">

                <div className=" flex flex-wrap justify-center items-end border border-gray-400 rounded-lg p-2 w-12 h-12 text-2xl text-gray-800 " >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 12 12"><path fill="#1f2937" d="M6 7.75a1.75 1.75 0 1 0 0-3.5a1.75 1.75 0 0 0 0 3.5m.75-5.68a4.004 4.004 0 0 1 3.18 3.18h1.32a.75.75 0 0 1 0 1.5H9.93a4.004 4.004 0 0 1-3.183 3.18a.759.759 0 0 1 .003.07v1.25a.75.75 0 0 1-1.5 0V10a.82.82 0 0 1 .003-.07A4.004 4.004 0 0 1 2.07 6.747A.759.759 0 0 1 2 6.75H.75a.75.75 0 0 1 0-1.5H2a.82.82 0 0 1 .07.003A4.004 4.004 0 0 1 5.25 2.07V.75a.75.75 0 0 1 1.5 0zM8.5 6a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0"/></svg>
                </div>
                 <div className="space-y-0.5 color-[#081a51] font-poppins font-lg  ml-2  text-left text-xl">
                    <div> Sites Count</div>
                    {/* <div className="text-md  font-poppins font-semibold text-gray-800 ">{ parseInt(DashboardSiteCount[0]?.success_count) +  parseInt(DashboardSiteCount[0]?.failed_count || 0)  }</div> */}
                    <div className="text-md  font-poppins font-semibold text-gray-800 ">{ parseInt(DashboardSiteCount[0]?.total_count) || 0  }</div>
                </div>
            </div>
            <div className=" pr-3 mb-2 mt-3 ml-8  justify-self-center  text-gray-500 lg:mb-3 ">
                <h3 className="text-base  place-items-start font-poppins font-light text-gray-900 ">
                    Intervention: {parseInt(DashboardSiteCount[0]?.active_count) || 0}
                </h3>
                <h3 className="text-base  place-items-center font-poppins font-light text-gray-900 ">
                    Control: {parseInt(DashboardSiteCount[0]?.inactive_count) || 0}
                </h3>
            </div>

        </div>
    </div>
</>
  )
}

export default SiteCountCard