// // * This is the Admin Portal Card Component that is used in the Admin Portal Page
import React from 'react'

const MonitoringCards = ({ title, desc, icon, activeTable, setActiveTable, data }) => {
    console.log("data", data);
    return (
        <div
            className={`flex mb-1 flex-col col-span-full sm:col-span-6 md:col-span-4 justify-center bg-white shadow-xl rounded-lg border ${activeTable === title ? 'border-gray-600' : 'border-gray-300'
                }`}
            onClick={() => {
                setActiveTable(title);
            }}
        >
            <div className="">
                <div class="flex justify-center items-center vr">
                    <div class="rp">
                        <div class="flex justify-center items-center w-20">
                            <div
                                class={`text-xl font-poppins font-bold mr-2 ${activeTable === title ? 'text-slate-800' : 'text-slate-300'
                                    }`}

                                onClick={() => {
                                    setActiveTable(title);
                                }}
                            >
                                {title}
                            </div>
                            <div className="d-inline" onClick={() => {
                                setActiveTable(title);
                            }}>{icon} </div>
                            
                            {/* <div class="text-sm gp yt">+7%</div> */}
                        </div>
                        <div class="text-2xl font-poppins font-bold text-center text-slate-700"> Failed: {

                            // map data so that if key matches desc then return value
                            data.map((item) => {
                                console.log("item", Object.keys(item));
                                // get key of item
                                const key = Object.keys(item);
                                // get value of item
                                const value = Object.values(item);
                                // if key matches desc then return value
                                if (key == desc) {
                                    return value;

                                }
                            })
                        }</div>
                    </div>
                    <div class="hidden qx of sf hu rp" aria-hidden="true"></div>
                </div>
            </div>
        </div>
    );
};

export default MonitoringCards;
