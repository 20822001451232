import React, { useState, useEffect, useContext } from 'react';
import { login } from '../api/Auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../Context/AuthProvider';
import loginLogo from '../assets/login_logo.png';
import initiativelogo from '../assets/initiative_logo.png';
import email_icon from '../assets/Folder.png';
import quit4tb_login_logo from '../assets/quit4tb_login_logo.png';
import banner from '../assets/banner.png';
import banner_bg from '../assets/banner-bg.png';
import banner_logo from '../assets/login_banner.png';

const LoginCard = () => {
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const navigate = useNavigate();
  const { handleLogin } = useContext(AuthContext);
  const { user } = useContext(AuthContext) || null;

  const loginUser = async (e) => {
    try {
      e.preventDefault();
      const res = await login(email, password);
      if (res.status === 200) {
        toast.success('Login Successful');
        localStorage.setItem('access_token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data));
        handleLogin(res.data);
        navigate('/');
      }
    } catch (error) { }
  };

  return (
    // <div>
    // for medium and above devices only
      <>
      {/* // <div> */}
      {/* // for medium and above devices only */}
      <div className="flex-row h-screen justify-between overflow-hidden hidden md:flex">
        <div className="flex h-screen w-1/3 justify-between">
          <div className="flex flex-grow justify-around"> {/* Add a flex container */}
            <div className="d-inline">
              <img className="w-24 mx-auto" src={loginLogo} alt="logo" />
            </div>
            <div className="d-inline">
              <img className="w-32 mt-4 mx-auto" src={initiativelogo} alt="logo" />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-2/3 h-[90] relative rounded-l-2xl z-0 bg-cover "
          style={{
            backgroundImage: `url(${banner})`,

            backgroundSize: 'cover', // This will cover the entire div
            backgroundRepeat: 'no-repeat',
            marginRight: `-23px`,
            marginTop: '-15px',
          }}
        >

          {/* <!-- Your other content here --> */}
          <section className="absolute z-999 top-1/2 mt-6 left-4/6 p-4   transform -translate-x-1/2 -translate-y-1/2 rounded-2xl">
            <div className="w-full max-w-md bg-white rounded-lg  shadow-xl border-slate-500 border-l-black p-8 relative">
              <div className="text-center mb-8">
                <img className="w-48 mx-auto" src={quit4tb_login_logo} alt="logo" />

              </div>
              <form onSubmit={loginUser} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block mb-2">Email</label>
                  <input type="email" id="email" className="w-full border rounded-full px-4 py-2" placeholder="Enter Your Email..." onChange={(e) => setemail(e.target.value)} />
                </div>

                <div>
                  <label htmlFor="password" className="block mb-2">Password</label>
                  <input type="password" id="password" className="w-full border rounded-full px-4 py-2" placeholder="Enter Your Password" onChange={(e) => setpassword(e.target.value)} />
                </div>
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Log in</button>
              </form>
            </div>
          </section>

        </div>






      </div>


      {/* // for small devices only
      // just show the login logo and initiative logo and then render the form only. no background image required */}
      <div className="flex flex-col  justify-between md:hidden">
        <div className="flex justify-around"> {/* Add a flex container */}
          <span >
            <img className="w-24 h-auto mx-auto" src={loginLogo} alt="logo" />
          </span>
          <div className="d-inline">
            <img className="w-32 h-auto mt-4 mx-auto" src={initiativelogo} alt="logo" />
          </div>
        </div>


        <div className="flex flex-col my-[100px] justify-center items-center bg-slate-200">
          <div className="w-full max-w-md bg-white rounded-2xl shadow-xl border-slate-500 p-3 relative">
            <div className="text-center mb-8">
              <img className="w-48 mx-auto" src={quit4tb_login_logo} alt="logo" />

            </div>
            <form onSubmit={loginUser} className="space-y-6">
              <div>
                <label htmlFor="email" className="block mb-2">Email</label>
                <input type="email" id="email" className="w-full border rounded-full px-4 py-2" placeholder="Enter Your Email..." onChange={(e) => setemail(e.target.value)} />
              </div>

              <div>
                <label htmlFor="password" className="block mb-2">Password</label>
                <input type="password" id="password" className="w-full border rounded-full px-4 py-2" placeholder="Enter Your Password" onChange={(e) => setpassword(e.target.value)} />
              </div>
              <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Log in</button>
            </form>
          </div>
        </div>
        </div>



      {/* // <footer className="py-4 bg-gray-800 text-white text-sm text-center">
      //   COPYRIGHT © 2023 Quit4TB, All rights Reserved
      //   Hand-crafted & Made by <a href="https://bikter.com" target="_blank" rel="noreferrer" className="text-blue-400 hover:text-blue-300">Bikter Tech</a>
      // </footer>
      </div>
       */}
    </>
  );
};

export default LoginCard;
