import moment from "moment";


// * Convert string to boolean
export function convertStringToBoolean(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === "Yes" ? true : value === "No" ? false : value,
    ])
  );
}

// * get date difference in days
export const getDateDifference = (date) => {
  const today = moment().add(1, "days");
  const currentDate = today.format("YYYY-MM-DD");
  const dateToCompare = moment(date).format("YYYY-MM-DD");
  const diff = today.diff(dateToCompare, "days");
  return diff;
};
