import { getAxiosInstance } from "../Config";
import { Navigate } from "react-router-dom";

export const getGroupData = async () => {
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .get("/group/getAllGroups")
      .then((response) => {

         if (response.status !== 200) {
           throw new Error(`Error: ${response.data}`);
         }
          return response.data;
      })
      .catch((error) => {
        console.error(error.message);
        // alert(error.message);
         alert(error.message);
      });
    };

// * create new group in database
export const createGroup = async ( formData ) => {
    console.log("formData", formData);
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .post("/group/createGroup", formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
        return error.message;
        // alert(error.message);
      });
    };


// * update group in database
export const updateGroup = async ( formData ) => {
    console.log("formData", formData);
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .put("/group/updateGroup", formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
        return error.message;
        // alert(error.message);
      });
    };

// * delete group from database
export const deleteGroup = async (ids) => {
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .delete("/group/deleteGroup", { data: { ids } })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
};

// * get non group members using group id
export const getNonGroupMembersByGroupId = async (groupId) => {
  console.log("groupId", groupId);

  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .get(`/group/getNonGroupMembers/${groupId}`)
    .then((response) => {
      console.log("non group response", response);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
};

// * get group members using group id
export const getGroupMembersByGroupId = async (groupId) => {
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .get(`/group/getGroupMembers/${groupId}`)
    .then((response) => {
      console.log("group members response", response);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
};

// * bulk add group members
export const bulkAddGroupMembers = async (groupId,patientIds) => {
  console.log("groupId", groupId);
  console.log("patientIds", patientIds);
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .post("/group/bulkAddPatientsToGroup", { data: { groupId, patientIds } })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
}

// * bulk remove group members
export const bulkRemoveGroupMembers = async (groupId,patientIds) => {
  console.log("groupId", groupId);
  console.log("patientIds", patientIds);
  const axiosInstance = await getAxiosInstance();
  return axiosInstance
    .post("/group/bulkRemovePatientsFromGroup", {
      data: { groupId, patientIds },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error.message);
      return error.message;
      // alert(error.message);
    });
}
