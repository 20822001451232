import React,{useEffect} from 'react'
import DashboardStatistics from '../../Components/ReportingDashboard/DashboardStatistics';
import InfoCards from '../../Components/ReportingDashboard/InfoCards';
import SelectRegion from '../../Components/ReportingDashboard/SelectRegion';
import SearchOptions from '../../Components/ReportingDashboard/SearchOptions';
import PageTitle from '../../Components/utils/PageTitle';

const ReportingDashboard = () => {
  useEffect(() => {
    document.title = "Dashboard";
   
  }, []);
  return (
    <>
    <PageTitle title="Reporting Dashboard" />

    <div className="w-full mx-0  bg-white h-screen ">
      <div className="container mx-auto p-3 object-cover border-red-700">
        <div className="flex p-3 flex-col flex-wrap justify-center md:flex-row ">
          {/* <div className="hidden md:flex justify-self-start">
            Hello, <br /> Here is what happening in your Project
          </div> */}
          {/* <div className='md:ml-10 pl-10'> */}
            {/* <SelectRegion /> */}
            <SearchOptions />

          {/* </div> */}
        </div>

        
        {/* <InfoCards /> */}
        <DashboardStatistics/>
      </div>
    </div>
    </>
  );
}

export default ReportingDashboard;