import React from 'react'

const PageTitle = ({title}) => {
  return (
    <>
        {/* Page header */ }
        <div className = "mb-8" >
              <h1 className="text-2xl md:text-3xl text-slate-800 font-poppins font-bold">{title} </h1>
            </div >
      </>
  )
}

export default PageTitle