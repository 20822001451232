
import React, { useState, useEffect } from 'react'


// * initial state for form data
const initialState = {
   "phone_number":"",
   "status":"",
}



const UpdatePatientDetailsModal = ({ EditPatientData, setEditPatientData , submitForm, setEditModalOpen }) => {


    const data = EditPatientData || initialState;
    const [formData, setFormData] = useState(data);
    const handleSubmit = (event) => {
        event.preventDefault();
        submitForm(formData);
        setFormData(initialState);
    };

    // * useCallback hook to update form data based on seelected row
    useEffect(() => {
        setFormData(data);
    }, [data]);




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

   

    const patient_status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];


    const renderInputFields = () => {

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-4">
                        <div className="text-sm">
                            <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Manage Patient Info </div>
                        </div>
                        <div className="space-y-3">
                            <div>
                             
                                <div>
                                    <label className="block text-sm font-poppins font-medium my-2" htmlFor="phone_num">Phone Num <span className="text-rose-500">*</span></label>
                                    <input id="phone_num" value={formData.phone_num} name="phone_num" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                                </div>
                             

                                <div>
                                    <label className="block text-sm font-poppins font-medium my-1" htmlFor="status">Status <span className="text-purple-500">*</span></label>
                                    <select id="status" defaultValue={formData?.status} onChange={handleInputChange} name='status' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="" disabled >Select Status</option>

                                        {/* set option value selected based on data.Country   */}
                                        {patient_status.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal footer */}
                    <div className="px-5 py-4 border-t border-slate-200">
                        <div className="flex flex-wrap justify-end space-x-2">
                            <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button>
                            <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-[#1b82dd] text-white">Add</button>
                        </div>
                    </div>
                </form>

            </>
        );

    };

    return (

        renderInputFields()
    )

}

export default UpdatePatientDetailsModal