// // * This is the Admin Portal Card Component that is used in the Admin Portal Page
import React from 'react'

// const AdminPortalCard = ({ title, desc, icon, ActiveTable, setActiveTable }) => {
//   console.log(ActiveTable);
//   return (
//       //  <div className="grid mb-8 mt-3 gap-3 border-gray-400 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3">
//       //   <div className="item drop-shadow-xl">
//       //     <div className="flex flex-col flex=1  border-gray-500 items-center justify-center p-8 text-start bg-white border rounded-2xl ">
//       //       <div className="flex items-center   justify-self-stretch space-x-3">
//       //         <AiFillFilePdf className=" border border-gray-400 rounded-lg p-2 w-12 h-12 text-2xl text-gray-800 dark:text-gray-800" />
//       //         <div className="space-y-0.5 font-poppins font-black  dark:text-white text-left text-xl">
//       //           <div> Export Report</div>
//       //           {/* <div className="text-sm font-poppins font-light text-gray-500 dark:text-gray-400">Developer at Open AI</div> */}
//       //         </div>
//       //       </div>
//       //       <div className=" pr-4 mb-3 mt-3 -ml-8  justify-self-start  text-gray-500 lg:mb-8 dark:text-gray-400">
//       //         <h3 className="text-lg  place-items-start font-poppins font-semibold text-gray-900 dark:text-white">
//       //           {/* {today.toLocaleDateString("en-US", options)} */}
//       //           FILE Pdf
//       //         </h3>
//       //       </div>
//       //     </div>
//       //   </div>
//       // </div>

//     <div className={"flex flex-col col-span-full sm:col-span-6 md:col-span-4 justify-center bg-white shadow-xl rounded-lg border" +
//       (ActiveTable === title
//       ? "border-gray-600" : " border-gray-100 ")
//             } onClick = { e => {
//   setActiveTable(title); 
// }} >
//       <div className="p-4">
//         <div class="flex justify-center items-center vr">
//           <div class="rp">
//             <div class="flex justify-center items-center">
//               <div className='d-inline'>
//                 {icon}
//               </div>
//               <div class={"text-3xl font-poppins font-bold  mr-2" + (ActiveTable === title ? "text-slate-800" :"text-slate-300" ) }> {title} </div>
//               {/* <div class="text-sm gp yt">+7%</div> */}
//             </div>
//             <div class="text-sm text-center text-slate-500">{desc}</div>
//           </div>
//           <div class="hidden qx of sf hu rp" aria-hidden="true"></div>
//         </div>
        
//       </div>
//     </div>
//   )
// }
// export default AdminPortalCard;




const AdminPortalCard = ({ title, desc, icon, activeTable, setActiveTable }) => {
  return (
    <div
      className={`flex flex-col col-span-full sm:col-span-6 md:col-span-4 justify-center bg-white shadow-xl rounded-lg border ${activeTable === title ? 'border-gray-600' : 'border-gray-100'
        }`}
      onClick={() => {
        setActiveTable(title);
      }}
    >
      <div className="p-4">
        <div class="flex justify-center items-center vr">
          <div class="rp">
            <div class="flex justify-center items-center">
              <div className="d-inline" onClick={() => {
                setActiveTable(title);
              }}>{icon}</div>
              <div
                class={`text-3xl font-poppins font-bold mr-2 ${activeTable === title ? 'text-slate-800' : 'text-slate-300'
                  }`}

                onClick={() => {
                  setActiveTable(title);
                }}
              >
                {title}
              </div>
              {/* <div class="text-sm gp yt">+7%</div> */}
            </div>
            <div class="text-sm text-center text-slate-500">{desc}</div>
          </div>
          <div class="hidden qx of sf hu rp" aria-hidden="true"></div>
        </div>
      </div>
    </div>
  );
};

export default AdminPortalCard;
