/* eslint-disable jsx-a11y/alt-text */
import React,{useState} from 'react'
import {
  AiOutlineDashboard,
  AiFillHome,
  AiOutlineUser,
  AiOutlineMessage,
  AiOutlineUsergroupDelete,
  AiOutlineBarChart,
  AiOutlineLayout,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { AuthContext } from "../Context/AuthProvider";

import { Link } from 'react-router-dom';
const Sidebar = () => {
 
    const [open, setOpen] = useState(true);
    const { user } = React.useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("");

  const handleMenuClick = (to) => {
    setSelectedTab(to);
  };

  // user?.role === "ADMIN" || user?.role === "SUPER_ADMIN" ?

  // const Menus = [
  //   { title: "Home", src: <AiOutlineDashboard size={20}/> ,to: "/" },
  //   { title: "Admin  Portal", src: <AiFillHome size={20} />, to: "/Admin-Portal" } : null,
  //   // { title: "Admin  Portal", src: <AiFillHome size={20} />, to: "/Admin-Portal" },
    
  //   { title: "Patient Management", src: <AiOutlineUser size={20} /> ,to: "/patient-management" },
  //   // { title: "Message Portal ", src: <AiOutlineMessage size={20} />, to: "/patient-Details" },
  //   { title: "Group Management", src: <AiOutlineUsergroupDelete size={20} />, to: "/group-management" },
  //   { title: "Reporting Dashboard", src: <AiOutlineBarChart size={20} />, to: "/Reporting-Dashboard" },
  //   { title: "Message Template", src: <AiOutlineLayout size={20}/> ,to: "/Template-Management" },
  //   { title: "Help", src: <AiOutlineQuestionCircle size={20} />, to: "/Help" },
  // ];

  const Menus = [
    { title: "Home", src: <AiOutlineDashboard size={20} />, to: "/" },
    { title: "Patient Management", src: <AiOutlineUser size={20} />, to: "/patient-management" },
    { title: "Message Template", src: <AiOutlineLayout size={20} />, to: "/template-management" },
    // { title: "Group Management", src: <AiOutlineUsergroupDelete size={20} />, to: "/group-management" },

    // { title: "Help", src: <AiOutlineQuestionCircle size={20} />, to: "/help" },
  ];

  // Conditionally add "Admin Portal" tab for admin and super admin
  if (user?.role === "ADMIN" || user?.role === "SUPER_ADMIN") {
    Menus.splice(3, 0, { title: "Admin Portal", src: <AiFillHome size={20} />, to: "/admin-portal" });
    Menus.splice(4, 0, { title: "Reporting Dashboard", src: <AiOutlineBarChart size={20} />, to: "/reporting-dashboard" });
  }
  // Conditionally add "Error Logs" tab for super admin
  if(user?.role === "SUPER_ADMIN") {

    Menus.splice(5, 0, { title: "Group Management", src: <AiOutlineUsergroupDelete size={20} />, to: "/group-management" });
    Menus.splice(6, 0, { title: "Monitoring", src: <AiOutlineBarChart size={20} />, to: "/monitoring" });
  }

  // Define a function to open the PDF file in a new tab
  const openHelpPDF = () => {
    const pdfURL = process.env.PUBLIC_URL + '/user_manual.pdf';
    window.open(pdfURL, '_blank');
  };

  return (
      <div
        className={` ${
          open ? "w-72" : "w-20"
        } bg-dark-purple h-screen p-5 relative  pt-8 mb-0 duration-300`}
      >
        <img
          src="/assets/control.png"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src="/assets/logo.png"
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white origin-left font-poppins font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            Quit4TB
          </h1>
        </div>
      <ul className="pt-6">
        {Menus.map((Menu, index) => (
          <Link
            key={index}
            className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4
            ${Menu.gap ? "mt-9" : "mt-2"} ${Menu.to === selectedTab ? "bg-light-white" : ""} peer/${Menu.to}`}
            to={Menu.to}
            onClick={() => handleMenuClick(Menu.to)}
          >
            <div className='w-100'>
              {Menu.src}
            </div>
            <span className={`${!open && "hidden"}  font-poppins origin-left duration-200`}>
              {Menu.title}
            </span>
          </Link>
        ))}

        <button
          className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
          onClick={openHelpPDF}
        >
          <div className='w-100'>
            <AiOutlineQuestionCircle size={20} />
          </div>
          <span className={`${!open && "hidden"}  font-poppins origin-left duration-200`}>
            Help
          </span>
        </button>
      </ul>

      </div>  
    
  );
}

export default Sidebar
