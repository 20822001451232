import React from 'react'

const UserRegion = ({ Region, setRegion }) => {
  return (
      <div> {/* Start */}
          <ul className="mt-2 flex flex-wrap -m-1">
              <li className="m-1">
                  <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
                      (Region === 1
                      ? "bg-[#081a51] text-white" : " bg-white text-slate-500")
                  } onClick={e => {
                      e.preventDefault();
                      setRegion(1);
                  }} >All</button>
              </li>
              <li className="m-1">
                  <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
                      (Region === 2
                          ? "bg-[#081a51] text-white"
                          : " bg-white text-slate-500")
                  } onClick={e => {
                      e.preventDefault();
                      setRegion(2);
                  }}>Pakistan</button>
              </li>
              <li className="m-1">
                  <button className={"inline-flex items-center justify-center text-sm font-poppins font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
                      (Region === 3
                          ? "bg-[#081a51] text-white"
                          : " bg-white text-slate-500")
                  } onClick={e => {
                      e.preventDefault();
                      setRegion(3);
                  }}>Bangladesh</button>
              </li>
          </ul>
          {/* End */}</div>
  )
}

export default UserRegion