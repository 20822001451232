import React, { useState, useEffect,useMemo,useCallback } from 'react';
import { CiHospital1 } from 'react-icons/ci';
import { AiOutlineUser, AiOutlineApi } from 'react-icons/ai';
import { fetchAllData, updateData, createData, deleteData } from '../../api/adminPortal';
import { toast } from 'react-toastify';
// import FetchDataAPI from '../../Hooks/FetchDataAPI';
import PageTitle from '../../Components/utils/PageTitle';
import AdminPortalCard from '../../Components/Cards/AdminPortalCards';
import DataTableTemplate from '../../Components/Tables/DataTableTemplate';
import AdminModalInputFields from '../../Components/Modals/AdminModalInputFields';
import ModalLayout from '../../Components/Modals/ModalLayout';
import Loader from '../../Loader';
import { AuthContext } from '../../Context/AuthProvider';
// import { getRemainingSMSinfo } from '../../api/AdminPortalAPIs/Pak/getRemainingSMSinfo';







const AdminPortal = () => {
  const [activeTable, setActiveTable] = useState("Sites");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [EditModalOpen, setEditModalOpen] = useState(false)
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  //* search filter
  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return data;
    }

    return data.filter(row => {
      return Object.values(row).some(value => {
        if (typeof value !== 'string') {
          return false;
        }
        return value?.toLowerCase().includes(searchQuery?.toLowerCase())
      }
      );
    });
  }, [data, searchQuery]);



  const Menus = [
    { title: "Sites", desc: "Site Management", icon: <CiHospital1 size={50} />, },

    // { title: "API", desc: "API Keys Info", icon: <AiOutlineApi size={50} />, },
  ];

  const { user } = React.useContext(AuthContext);

  if (user?.role === "ADMIN" || user?.role === "SUPER_ADMIN") {
    Menus.splice(1, 0, { title: "Users", desc: "Manage Access", icon: <AiOutlineUser size={50} /> });
  }

  if ( user?.role === "SUPER_ADMIN") {
    Menus.splice(2, 0, { title: "APIs", desc: "Manage SMS API", icon: <AiOutlineUser size={50} /> });
  }

// get remainging sms info form api providers
// const remianingSMSinfo = async () => {
//   const smsInfo = await getRemainingSMSinfo();
//   console.log(smsInfo);
// }

 

  useEffect(() => {
    // remianingSMSinfo();
    setLoading(true);
    setData(null);
    fetchData(activeTable);
  }, [activeTable]);

  // * fetching data method
  const fetchData = async (activeTable) => {
    // * fetch data from api and show toast
     await fetchAllData(activeTable)
      .then((res) => {
        if (!res || !res.data) {
          data && setData(null);
          setLoading(false);
          return toast.error("Failed to fetch data");
        }
        toast.success("Data fetched successfully");
        setLoading(false);
        setData(res.data);
        setColumns(Object.keys(res.data[0]).map((key) => {
          return {
            name: key,
            selector: row => row[key],
            sortable: true,
          }
        }))
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });



  };







 // * handle row selection (checkbox)
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  // * Action context for selected rows
  const contextActions = useMemo(() => {
    return (
      <>
      <div className="flex justify-end gap-2  ">
          {/* {selectedRows.length < 2 ? <button type="button" key="edit" onClick={(e) => { e.stopPropagation(); console.log("selected row:",selectedRows[0].name); setEditModalOpen(true); }} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> */}
          {selectedRows.length < 2 ? <button type="button" key="edit" onClick={(e) => {e.stopPropagation(); handleAction('edit', selectedRows, activeTable)}} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
          >
            <path d="M6.3 12.3l10-10a1 1 0 011.4 0l4 4a1 1 0 010 1.4l-10 10a1 1 0 01-.7.3H7a1 1 0 01-1-1v-4a1 1 0 01.3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 012 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h6a1 1 0 010 2H4v14h14v-6z" />
          </svg>
          Edit
        </button>: null}
          <button type="button" key="delete" onClick={() => handleAction('delete', selectedRows, activeTable)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" />
            </svg>
            Delete
          </button>
        </div>
      </>
    );
  }, [selectedRows]);

  // * handle actions on selected rows
  const handleAction = async (action, row, currentTable) => {
    switch (action) {
      case 'edit':
        console.log("open edit modal");
        setEditModalOpen(true);
        break;
      case 'delete':
        const confirm = window.confirm("Are you sure you want to delete this record?");
        if (confirm) {
          // extract ids of all selected row
          const ids = row.map((item) => item.id);
          console.log(ids);

          // create an array of objects with just the id property
          // const idObjects = ids.map((id) => ({ id }));
          // console.log(idObjects);

          // delete selected rows
          // await deleteData(currentTable, idObjects);
          await deleteData(currentTable, ids);
          fetchData(currentTable);
          // setToggleClearRows(!toggledClearRows);

        }

        break;
      default:
        break;
    }
  };

  

  const handleFormSubmit = async(data,table,editmode) => {
    // do something with the form data, like send it to a server or update state
    if(editmode){
      setEditModalOpen(false);
      await updateData(table, data);
      setSelectedRows([]);
      fetchData(table);
      setToggleClearRows(!toggledClearRows);
    }
    else{
      setEditModalOpen(false);
      await createData(table, data);
      setSelectedRows([]);
      fetchData(table);
      setToggleClearRows(!toggledClearRows);

    }
  };



  
  return (
    <>
      <PageTitle title="Admin Portal" />
      <div className="grid grid-cols-12 gap-2">
        {Menus.map((menu, index) => (
          <AdminPortalCard
            key={index}
            title={menu.title}
            desc={menu.desc}
            icon={menu.icon}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
          />
        ))}
      </div>
      <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        {selectedRows.length < 1 ? (<button type="button" onClick={(e) => { e.stopPropagation(); setEditModalOpen(true); }} class="inline-flex items-center px-4 py-2 my-3 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-[#0F6CBD] hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20a10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16z" /></svg>
          <p className='ml-2'> New {activeTable}</p>
        </button>
        ): null} 
        {/* <ModalLayout id={`add-${activeTable}`} modalOpen={AddModalOpen} setModalOpen={AddModalOpen} title={`Add New ${activeTable}`}>
          <div className="flex flex-col w-full">
             <AdminModalInputFields selectedRow={selectedRows} activeTable={activeTable} isEdit={false} submitForm={handleFormSubmit} setModalOpen={setAddModalOpen} />
          </div>
        </ModalLayout> */}

        <ModalLayout  id={`edit-${activeTable}`} modalOpen={EditModalOpen} setModalOpen={setEditModalOpen} title={selectedRows.length > 0 ? `Edit ${activeTable}` : `Add new ${activeTable}`}>
          <div className="flex flex-col w-full">
            {selectedRows.length > 0 ?
            <AdminModalInputFields selectedRow={selectedRows} activeTable={activeTable} isEdit={true} submitForm={handleFormSubmit} setModalOpen={setEditModalOpen} /> 
            : <AdminModalInputFields selectedRow={selectedRows} activeTable={activeTable} isEdit={false} submitForm={handleFormSubmit} setModalOpen={setEditModalOpen} />
          }
          </div>
        </ModalLayout>
    </div>
      <div className="mt-4">
        {
          loading ? <Loader /> :
          data ? (
              <div className="overflow-x-auto ">

            <div class="relative float-right">
              <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              </div>
              <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
                value={searchQuery}
                onChange={event => setSearchQuery(event.target.value)}
                placeholder="Search..." />
              {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
            </div>
                <DataTableTemplate data={filteredData} columns={Columns} title={`Data for ${activeTable}`}
           contextActions={contextActions} handleRowSelected={handleRowSelected}
            toggledClearRows = {toggledClearRows} setToggleClearRows={setToggleClearRows}
           /> 
           </div>
           ) : <div className="text-center">No Data Found</div>
        } 
      </div>
    </>
  );
};

export default AdminPortal;
