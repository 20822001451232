
import React,{useState,useEffect} from 'react'


// * initial state for form data
const initialState = {
  "id": '',
  "name": '',
  "email": '',
  "role": '',
  "country": '',
  "password": '',
  "status": '',

  "siteId":'',
  "siteName":'',
  "siteCountry":'',
  "siteStatus":'',

  "packageName" : '',
  "totalSMS": '',
  "remainingSMS": '',
  "purchaseDate": '',
  "expiryDate": '',
  "isActivated": '',
  "apiCountry": '',


}



const AdminModalInputFields = ({ selectedRow, activeTable, submitForm, setEditModalOpen, isEdit  }) => {

 
  const data = selectedRow[0] || initialState;
  const [formData, setFormData] = useState(data);
  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm(formData, activeTable, isEdit);
    setFormData(initialState);
  };

  // * useCallback hook to update form data based on seelected row
  useEffect(() => {
    setFormData(data);
  }, [data]); 




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const roles = [
    { value: "MANAGER", label: "Manager" },
    { value: "ADMIN", label: "Admin" },
    { value: "SUPER_ADMIN", label: "Super Admin" },
    { value: "RESEARCH_ASSISTANT", label: "Research Assistant" },
  ];

  const countries = [
    { value: "Pakistan", label: "Pakistan" },
    { value: "Bangladesh", label: "Bangladesh" },
  ];


  const renderInputFields = () => {
    switch (activeTable) {
      case 'Users':
        return (
        <>
            <form onSubmit={handleSubmit}>
              <div className="px-5 py-4">
                <div className="text-sm">
                  <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Manage {activeTable}  </div>
                </div>
                <div className="space-y-3">
                      <div>
                        <div>
                          <label className="block text-sm font-poppins font-medium my-1" htmlFor="name">Name  <span className="text-rose-500">*</span></label>
                      {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required />: null}
                          <input id="name" value={formData.name} name="name" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                        </div>
                        <div>
                          <label className="block text-sm font-poppins font-medium my-2" htmlFor="email">Email <span className="text-rose-500">*</span></label>
                          <input id="email" value={formData.email} name="email" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                        </div>
                        {
                      !isEdit ?(<div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="password">Password <span className="text-rose-500">*</span></label>
                        <input id="password" name="password" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="password" required />
                      </div>):null
                        }
                        <div>
                          {/* roles */}
                          <label className="block text-sm font-poppins font-medium my-1" htmlFor="Role">Role <span className="text-purple-500">*</span></label>
                          <select id="role" name="role" defaultValue={formData?.role} onChange={handleInputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="" disabled >Select Role</option>
                            {roles.map((option) => (
                              <option key={option.value} value={option.value} selected={option.value === formData.role} >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label className="block text-sm font-poppins font-medium my-1" htmlFor="Country">Country <span className="text-purple-500">*</span></label>
                          <select id="Country" defaultValue={formData?.country} onChange={handleInputChange} name='Country' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="" disabled >Select Country</option>

                            {/* set option value selected based on data.Country   */}
                            {countries.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label className="block text-sm font-poppins font-medium my-1" htmlFor="status">Status <span className="text-purple-500">*</span></label>
                      <select id="status" name='status' onChange={handleInputChange} defaultValue={formData?.status} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="" disabled >Select Status</option>
                            <option value="active">active</option>
                            <option value="inactive">inactive</option>
                          </select>
                        </div>
                      </div>
                </div>
              </div>

              {/* Modal footer */}
              <div className="px-5 py-4 border-t border-slate-200">
                <div className="flex flex-wrap justify-end space-x-2">
                  <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button>
                  <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-[#1b82dd] text-white">Add</button>
                </div>
              </div>
            </form>
            
          </>
        );
      case 'Sites':
        return (
          <>
            <form onSubmit={handleSubmit}>
              <div className="px-5 py-4">
                <div className="text-sm">
                  <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Manage {activeTable} </div>
                </div>
                <div className="space-y-3">
                  <div>
                    <div>
                      <label className="block text-sm font-poppins font-medium my-1" htmlFor="site Id">Site Id  <span className="text-rose-500">*</span></label>
                      {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required /> : null}
                      <input id="siteId" value={formData.siteId} name="siteId" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                    </div>
                    <div>
                      <label className="block text-sm font-poppins font-medium my-1" htmlFor="Site Name">Site Name  <span className="text-rose-500">*</span></label>
                      {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required /> : null}
                      <input id="siteName" value={formData.siteName} name="siteName" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                    </div>
                  
                  <div>
                      <label className="block text-sm font-poppins font-medium my-1" htmlFor="Site Country">Site Country <span className="text-purple-500">*</span></label>
                      <select id="siteCountry" defaultValue={formData?.siteCountry} onChange={handleInputChange}  name='siteCountry' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="" disabled >Select Country</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Bangladesh">Bangladesh</option>
                    </select>
                  </div> 
                  <div>
                      <label className="block text-sm font-poppins font-medium my-1" htmlFor="Site Status">Site Status <span className="text-purple-500">*</span></label>
                      <select id="siteStatus" name='siteStatus' onChange={handleInputChange} defaultValue={formData?.siteStatus} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="" disabled >Select Status</option>
                      <option value="active">active</option>
                      <option value="inactive">inactive</option>
                    </select>
                  </div>
                  </div>
                </div>
              </div>

              {/* Modal footer */}
              <div className="px-5 py-4 border-t border-slate-200">
                <div className="flex flex-wrap justify-end space-x-2">
                  {/* <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button> */}
                  <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-indigo-600 text-white">Add</button>
                </div>
              </div>
            </form>
               
          </>
        );
      
      case 'APIs':
          return (
            <>
              <form onSubmit={handleSubmit}>
                <div className="px-5 py-4">
                  <div className="text-sm">
                    <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Manage {activeTable} </div>
                  </div>
                  <div className="space-y-3">
                    <div>
                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="site Id">Package Name  <span className="text-rose-500">*</span></label>
                        {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required /> : null}
                        <input id="packageName" value={formData.packageName} name="packageName" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                      </div>

                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="Site Name">Total SMS  <span className="text-rose-500">*</span></label>
                         <input id="totalSMS" value={formData.totalSMS} name="totalSMS" onChange={handleInputChange} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />   
                      </div>

                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="purchaseDate">Purchase Date <span className="text-rose-500">*</span></label>
                        <input id="purchaseDate" name='purchaseDate' onChange={handleInputChange} defaultValue={formData?.purchaseDate} className="form-input w-full px-2 py-1 rounded-lg" type="date" required />
                      </div>


                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="expiryDate">Expiry Date <span className="text-rose-500">*</span></label>
                        <input id="expiryDate" name='expiryDate' onChange={handleInputChange} defaultValue={formData?.expiryDate} className="form-input w-full px-2 py-1 rounded-lg" type="date" required />
                      </div>

                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="remainingSMS">Remaining SMS <span className="text-slate-500">(optional)</span></label>
                        <input id="remainingSMS" name='remainingSMS' onChange={handleInputChange} defaultValue={formData?.remainingSMS} className="form-input w-full px-2 py-1 rounded-lg" type="text" required />
                      </div>

                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="API Country">API Country <span className="text-purple-500">*</span></label>
                          <select id="apiCountry" defaultValue={formData?.apiCountry} onChange={handleInputChange} name='apiCountry' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option value="" disabled >Select Country</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Bangladesh">Bangladesh</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-poppins font-medium my-1" htmlFor="isActivated">is Activated <span className="text-purple-500">*</span></label>
                        <select id="isActivated" name='isActivated' onChange={handleInputChange} defaultValue={formData?.isActivated } class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option value="" disabled >Select Status</option>
                          <option value="1">true</option>
                          <option value="0">false</option>
                        </select>
                      </div>

                     

                     

                        
                    </div>
                  </div>
                </div>

                {/* Modal footer */}
                <div className="px-5 py-4 border-t border-slate-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    {/* <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button> */}
                    <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-indigo-600 text-white">Add</button>
                  </div>
                </div>
              </form>

            </>
            )
            
      default:
        return null;
    }
  };

return (
    
       renderInputFields()
)
       
}

export default AdminModalInputFields