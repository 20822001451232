import { getAxiosInstance } from "../Config";

// * send group sms with message content and group id
export const sendGroupSMS = async (groupId, message) => {
    const axiosInstance = await getAxiosInstance();
    return axiosInstance
      .post("/group/sendGroupSMS", { data: { groupId, message } })
      .then((response) => {
        // if (response.status === 200) {
            console.log("sendGroupSMS", response.data);
          return response.data;
        // } else {
          // throw new Error(response.data.message);
        // }
      })
      .catch((error) => {
        console.error(error.message);
        return error.message;
        // alert(error.message);
      });
    }
