import React from 'react'
import LineChart03 from '../../../charts/LineChart03';
import { chartData } from '../../../charts/dummy';
import Icon from '../../../images/icon-01.svg';
import { AiOutlineArrowUp } from 'react-icons/ai';



const StatsCard = ({ cardTitle, cardValue, isChart = false, isUp = false, cardData = null, smsCount = null }) => {
  return (

    <div className="flex flex-col h-49 col-span-full sm:col-span-6 xl:col-span-4 bg-white border border-slate-200 ">
      <div className="p-7">
        <header className="flex justify-center items-start mb-2">
          <h2 className="text-lg text-center font-poppins font-bold text-slate-800 mb-2">{cardTitle}</h2>

          {/* Icon */}
          {/* <img src={Icon} width="32" height="32" alt="Icon 01" /> */}
          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link className="font-poppins font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" to="#0">Option 1</Link>
            </li>
            <li>
              <Link className="font-poppins font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" to="#0">Option 2</Link>
            </li>
            <li>
              <Link className="font-poppins font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3" to="#0">Remove</Link>
            </li>
          </EditMenu> */}
        </header>
        {
          !isChart && !cardData && !smsCount ? 
            <div className="flex flex-col items-center py-5 justify-center  border rounded-lg shadow-md  nm-flat-gray-50-lg ">
              <h2 className="text-3xl text-center font-poppins font-bold mb-2">{cardValue}</h2>
              {isUp &&( <div className="flex items-center text-green-800 text-sm font-poppins font-semibold">
                <AiOutlineArrowUp size={30} className="mr-" />
                <p className='font-poppins font-light text-base'> 25%</p>
                </div>) }
            </div>:null
        }

        {
          cardData ?
        <div className="flex flex-col items-center py-5 justify-center  border rounded-lg shadow-md  nm-flat-gray-50-lg ">
              <h2 className="text-2xl text-center font-poppins font-bold mb-2"> Pakistan: {parseInt(cardData?.Pakistan || 0)}</h2>
              <h2 className="text-2xl text-center font-poppins font-bold my-2"> Bangladesh: {parseInt(cardData?.Bangladesh || 0)}</h2>
          {isUp && (<div className="flex items-center text-green-800 text-sm font-poppins font-semibold">
            <AiOutlineArrowUp size={30} className="mr-" />
            <p className='font-poppins font-light text-base'> 25%</p>
          </div>)}
        </div>:null
        }


        {
          smsCount ?
            <div className="flex flex-col items-center py-5 justify-center  border rounded-lg shadow-md  nm-flat-gray-50-lg ">
              <h2 className="text-2xl text-center font-poppins font-bold mb-2"> Sent: {parseInt(smsCount?.success_count || 0)}</h2>
              <h2 className="text-2xl text-center font-poppins font-bold my-2"> Failed: {parseInt(smsCount?.failed_count || 0)}</h2>
              {isUp && (<div className="flex items-center text-green-800 text-sm font-poppins font-semibold">
                <AiOutlineArrowUp size={30} className="mr-" />
                <p className='font-poppins font-light text-base'> 25%</p>
              </div>)}
            </div> : null
        }
      
      {/* Chart built with Chart.js 3 */}
      {
          isChart ?
            // <div className="grow  nm-flat-gray-50-lg border rounded shadow-md ">
           <div className=" ">
            {/* Change the height attribute to adjust the chart height */}
            <LineChart03 data={chartData} width={389} height={128} />
          </div>
          : null

      }
      
      </div>
    </div>
  )
}

export default StatsCard