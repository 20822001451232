import React,{useState} from 'react'
import UserRegion from '../../Components/Home/UserRegion';
import DetailsCard from '../../Components/Home/DetailsCard';
import Stats from '../../Components/Home/Stats';
import DashboardSitePatientCountStatus from '../../Components/Cards/DashboardSitePatientCountStatus';
import PatientEnrollmentOverTime from '../../charts/PatientEnrollmentOverTime';
import { tailwindConfig } from '../../Components/utils/Utils';
import CountryChart from '../../Components/ReportingDashboard/DashboardStatisticsCards/CountryChart';
import { getEnrollmentCount } from '../../api/Home/api';
import Loader from '../../Loader';
import { AuthContext } from '../../Context/AuthProvider';
const Home = () => {

  const { user } = React.useContext(AuthContext);

  // Function to format the date as needed for chart labels
  const formatChartLabel = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}-${day}-${date.getFullYear()}`;
  };
  
  // * method to get user region based on its country
 const getRegionFromCountry = (country) => {
    switch (country) {
      case "Pakistan":
        return 2;
      case "Bangladesh":
        return 3;
      default:
        return 1;
    }
  };


  // Regions: 1 = All Regions, 2 = Pakistan, 3 = Bangladesh, 
  const [Region, setRegion] = useState(null);
  const [enrolmentMonth, setenrolmentMonth] = useState([]);
  const [enrolmentCount, setenrolmentCount] = useState([]);
  const [PakistanEnrollment, setPakistanEnrollment] = useState([]);
  const [BangladeshEnrollment, setBangladeshEnrollment] = useState([]);
  // const [total, setTotal] = useState(0);

  React.useEffect(() => {
    console.log("user",user);
    // setRegion(getRegionFromCountry(user?.country));
    
    if (user?.role == "RESEARCH_ASSISTANT" || user?.role == "MANAGER") {
      setRegion(getRegionFromCountry(user?.country));
    } 
    else{
      setRegion(1);
    }     
  }, []);


//   React.useEffect(() => {
//     getEnrollmentCount()
//       .then((res) => {
//         console.log(res.data);
  
//         // Set enrollment data
//         const data = res.data;
  
//         const pakistanEnrollment = Array(labels.length).fill(0);
//         const bangladeshEnrollment = Array(labels.length).fill(0);
  
//         data.forEach((item) => {
//           const index = labels.indexOf(item.enrollment_month);
//           if (index !== -1) {
//             if (item.patient_country === 'Pakistan') {
//               pakistanEnrollment[index] = item.enrollment_count;
//             } else if (item.patient_country === 'Bangladesh') {
//               bangladeshEnrollment[index] = item.enrollment_count;
//             }
//           }
//         });
  
//         setPakistanEnrollment(pakistanEnrollment);
//         setBangladeshEnrollment(bangladeshEnrollment);
//         setenrolmentMonth(1);
//         // setenrolmentMonth(data.map((item) => formatChartLabel(item.enrollment_month)));

//         console.log("pakistanEnrollment",pakistanEnrollment);
//         console.log("bangladeshEnrollment",bangladeshEnrollment);
//       })
//       .catch((err) => {
//         console.log(err);
//         setenrolmentMonth([]);
//       });
//   }, []);
  
//   // Define labels and chartData outside of the useEffect
//   const currentDate = new Date();
// const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
// const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
// const labels = [];
// for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
//   const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
//   labels.push(formattedDate);
// }
// console.log("labels", labels);

  
//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         label: 'Pakistan',
//         data: [8, 4, 6, 2, 3, 7],
//         // data: PakistanEnrollment || 0,
//         // backgroundColor: tailwindConfig().theme.colors.indigo[500],
//         backgroundColor: "#14d414",
//         hoverBackgroundColor: "#1Ff424",
//         barPercentage: 0.66,
//         categoryPercentage: 0.66,
//       },
//       {
//         label: 'Bangladesh',
//         data: BangladeshEnrollment || [],
//         backgroundColor: "#fc282d",
//         hoverBackgroundColor: "#f12f2a",
//         barPercentage: 0.66,
//         categoryPercentage: 0.66,
//       },
//     ],
//   };
  
  return (
    <div>
      {
        Region && 

        <div className="w-full mx-0  bg-white h-screen ">
          <div className="container mx-auto p-3 object-cover border-red-700">
            <div className="flex p-3 flex-col flex-wrap  justify-between  md:flex-row ">
              <div className="hidden md:flex font-poppins">
                Hello, <br /> Here is what happening in your Project
              </div>
              {
                user?.role === "SUPER_ADMIN" || user?.role === "ADMIN" ?
                  <UserRegion Region={Region} setRegion={setRegion} />
                  : null
              }
            </div>
            <DetailsCard Region={Region} />


            {/* <div className='grid grid-cols-12 gap-6'>

              <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">Patient Ennrollment This Month </h2>
                </header>
                {
                  enrolmentMonth.length === 0 ? <Loader /> :
                    <PatientEnrollmentOverTime Region={Region} data={chartData} width={595} height={248} />}
              </div>

              <div className=" flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border p-4 border-slate-200">
                <DashboardSitePatientCountStatus Region={Region} />
              </div>

            </div> */}

            {/* <Stats Region={Region} /> */}
            {/* <CountryChart Region={Region} /> */}
            {/* <InfoCards /> */}
            {/* <DashboardStatistics /> */}
          </div>
        </div>
      }
    </div>
  )
}

export default Home