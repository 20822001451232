import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function ManagePatientStatus({ currentStatus, isActive, setisActive, changeStatus }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(currentStatus);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    }, [dropdownOpen]);

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    }, [dropdownOpen]);

    const toggleStatus = (status) => {
        setSelected(status);
        setDropdownOpen(false);
        changeStatus(status);
    };

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger}
                className="btn justify-between min-w-20 ml-1 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                aria-label="Select patient status"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <span className="flex items-center">
                    {/* <span>{isActive ? 'Active' : 'Inactive'}</span> */}
                    {currentStatus === 'Active' ?
                    <span> Active</span>
                    :  
                    <span> Inactive</span>
                }

                </span>
                <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                    <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                </svg>
            </button>

            {/* select */}
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600"
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    <button
                        tabIndex="0"
                        className={`flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer ${selected === 'Active' &&
                            'text-indigo-500'}`}
                        onClick={() => toggleStatus('Active')}
                    >
                        <span>{"Active"}</span>
                    </button>

                    <button
                        tabIndex="0"
                        className={`flex items-center w-full hover:bg-slate-50 py-1 px-4 cursor-pointer ${selected === 'Inactive' &&
                            'text-indigo-500'}`}
                        onClick={() => toggleStatus('Inactive')}
                    >
                        <span className='pr-2'>{"Inactive"}</span>
                    </button>
                </div>
            </Transition>
        </div>
    );
}

export default ManagePatientStatus;