import { getAxiosInstance } from "../Config";

//* GET PATIENT ENROLLMENT OVER TIME REPORT BY MONTH
export const patientEnrollmentReport = async () => {
    const axiosInstance = await getAxiosInstance();
  
    return axiosInstance
      .get("/reporting/getEnrollmentStatSPerMonth")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Error: ${response.data}`);
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error.message);
      });
  };


  //* GET PATIENT ENROLLMENT OVER TIME REPORT BY MONTH
export const AnalyticsBySite = async () => {
  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/reporting/getSiteAnalytics")
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
    });
};


//* GET PATIENT data for CSV
export const getCSV_Data = async () => {
  const axiosInstance = await getAxiosInstance();

  return axiosInstance
    .get("/reporting/generateCSV")
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Error: ${response.data}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error.message);
    });
};
  
  