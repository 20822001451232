import React,{useEffect,useState,useMemo,useCallback} from 'react'
import PageTitle from '../../Components/utils/PageTitle';
import DataTableTemplate from '../../Components/Tables/DataTableTemplate';
import { CiHospital1 } from 'react-icons/ci';
import { AiOutlineUser, AiOutlineApi } from 'react-icons/ai';
import MonitoringCards from '../../Components/Cards/MonitoringCards';
import CronJobsMonitoringData from '../../Components/Monitoring/CronJobsMonitoringData';


import { fetchAllFailedMessagesLogs, reAttemptFailedMessage, fetchSMSCount, monitoring_remainingSmsSummary } from '../../api/AdminPortalAPIs/monitoringLogs'
import { toast } from 'react-toastify';
const Monitoring = () => {
    const [failedMessagesLogs, setFailedMessagesLogs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeTable, setActiveTable] = useState("All");

    const Menus = [
        { title: "All", desc: "Total", icon: <CiHospital1 size={50} />, },
        { title: "Pak", desc: "Pakistan", icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 640 480"><defs><clipPath id="flagPk4x30"><path fill-opacity=".7" d="M-52.3 0h682.6v512H-52.3z" /></clipPath></defs><g fill-rule="evenodd" stroke-width="1pt" clip-path="url(#flagPk4x30)" transform="translate(49) scale(.9375)"><path fill="#0c590b" d="M-95 0h768v512H-95z" /><path fill="#fff" d="M-95 0H97.5v512H-95z" /><g fill="#fff"><path d="m403.7 225.4l-31.2-6.6l-16.4 27.3l-3.4-31.6l-31-7.2l29-13l-2.7-31.7l21.4 23.6l29.3-12.4l-15.9 27.6l21 24z" /><path d="M415.4 306a121.2 121.2 0 0 1-161.3 59.4a122.1 122.1 0 0 1-59.5-162.1A118.6 118.6 0 0 1 266 139a156.2 156.2 0 0 0-11.8 10.9A112.3 112.3 0 0 0 415.5 306z" /></g></g></svg>, },
        { title: "Ban", desc: "Bangladesh", icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 640 480"><path fill="#006a4e" d="M0 0h640v480H0z" /><circle cx="280" cy="240" r="160" fill="#f42a41" /></svg>, },
        // { title: "API", desc: "API Keys Info", icon: <AiOutlineApi size={50} />, },
    ];


    // get failed sms count
    const [smsCount, setSmsCount] = useState(0);

    const [remainingSmsSummary, setremainingSmsSummary] = useState(0);

    const fetchSMSCountData = async () => {
        const res = await fetchSMSCount();
        setSmsCount(JSON.parse(res.data));
    }



    const getActiveTableData = async () => {
        if (activeTable === "All") {
            fetchAllFailedMessagesLogs()
                .then((res) => {
                    console.log(res.data)
                    setFailedMessagesLogs(res.data)
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else if (activeTable === "Pak") {
            fetchAllFailedMessagesLogs()
                .then((res) => {
                    console.log(res.data)
                    setFailedMessagesLogs(res.data.filter((item) => item.Country == "Pakistan")) // filter data based on country
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else if (activeTable === "Ban") {
            fetchAllFailedMessagesLogs()
                .then((res) => {
                    console.log(res.data)
                    setFailedMessagesLogs(res.data.filter((item) => item.Country == "Bangladesh")) // filter data based on country
                }
                )
                .catch((err) => {
                    console.log(err.message)
                }
                )
        }
    };

    const getRemainingSmsSummary = async() =>
    {
        console.log("summary called");
        const res = await monitoring_remainingSmsSummary();
        setremainingSmsSummary(res);
        console.log(remainingSmsSummary);

        

    }

    // filter data based on active table
    useEffect(() => {
        fetchSMSCountData();
        getActiveTableData();
        getRemainingSmsSummary();

       
    }, [activeTable])


    // * handle actions on selected rows
    const handleAction = async (action, row) => {

        switch (action) {
            case 'retry':
                console.log(row);
                toast.promise(reAttemptFailedMessage(row), {
                    pending: 'Re-attempting message...',
                    success: 'Message re-attempted successfully',
                    error: 'Error in re-attempting message',
                });
                getActiveTableData();
                
                // setEditPatientModalOpen(true);
                break;
            case 'delete':
                const confirm = window.confirm("Are you sure you want to re-attempt this message?");
                if (confirm) {
                    
                   
                    setToggleClearRows(!toggledClearRows);
                }

                break;
            default:
                break;
        }
    };

    // * Action context for selected rows
    const contextActions = useMemo(() => {
        return (
            <>
                <div className="flex justify-end gap-2  ">
                    {selectedRows.length < 2 ?
                        <button type="button" key="edit" onClick={(e) => { e.stopPropagation(); handleAction('edit', selectedRows) }} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                            >
                                <path d="M6.3 12.3l10-10a1 1 0 011.4 0l4 4a1 1 0 010 1.4l-10 10a1 1 0 01-.7.3H7a1 1 0 01-1-1v-4a1 1 0 01.3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 012 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h6a1 1 0 010 2H4v14h14v-6z" />
                            </svg>
                            Edit
                        </button> : null
                    }
                    <button type="button" key="delete" onClick={() => handleAction('delete', selectedRows)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                        >
                            <path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" />
                        </svg>
                        Delete
                    </button>
                </div>
            </>
        );
    }, [selectedRows]);

    // * handle row selection (checkbox)
    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, [selectedRows]);



// create columns based on above data and create buttons for retry sms in action column

    // const retrySMS = (row) => {
    //     console.log(row)
    // }

    const columns = [
        {
            name: 'Patient Id',
            selector: 'PatientId',
            sortable: true,
        },
        {
            name: 'Day',
            selector: 'DayIndex',
            sortable: true,
        },
        {
            name: 'Message Index',
            selector: 'MessageIndex',
            sortable: true,
        },
        {
            name: 'Attempt',
            selector: 'AttemptCount',
            sortable: true,
        },
        // {
        //     name: 'CreatedAt',
        //     selector: 'CreatedAt',
        //     sortable: true,
        // },
        // {
        //     name: 'UpdatedAt',
        //     selector: 'UpdatedAt',
        //     sortable: true,
        // },
        // {
        //     name: 'Error ',
        //     selector: 'failedMsgError',
        //     sortable: true,
        // },
        // {
        //     name: 'Error Code',
        //     selector: 'failedMsgErrorCode',
        //     sortable: true,
        // },
        {
            name: 'Country',
            selector: 'Country',
            sortable: true,
        },
        {
            name: 'PhoneNumber',
            selector: 'PhoneNumber',
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <button 
            className = "bg-slate-800 text-white px-2 py-1 rounded"
             onClick={() => handleAction('retry',row)}>Retry SMS</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    //* search filter
    const filteredData = useMemo(() => {
        if (!searchQuery) {
            return failedMessagesLogs ;
        }

        return failedMessagesLogs.filter(row => {
            return Object.values(row).some(value => {
                if (typeof value !== 'string') {
                    return false;
                }
                return value?.toLowerCase().includes(searchQuery?.toLowerCase())
            }
            );
        });
    }, [failedMessagesLogs, searchQuery]);

        
  return (
    <div>
        <PageTitle title='Monitoring' />
          <div className="flex flex-1 border rounded py-4 mb-2">
              {remainingSmsSummary ? (
                  <div className="flex flex-grow justify-around text-black font-semibold">
                      <div className="sms-summary">
                          <p>
                              <span className="font-bold">Pakistan:</span>
                              <span className="italic">{remainingSmsSummary?.Pakistan}</span>
                          </p>
                      </div>
                      | <div className="sms-summary">
                          <p>
                              <span className="font-bold">Pakistan Expiry Date:</span>
                              <span className="italic">{remainingSmsSummary?.PakSmsExpiryDate}</span>
                          </p>
                      </div>
                      |
                      <div className="sms-summary">
                          <p>
                              <span className="font-bold">Bangladesh:</span>
                              <span className="italic">{remainingSmsSummary?.Bangladesh}</span>
                          </p>
                      </div>

                  </div>
              ) : (
                  <div className="text-black font-semibold">
                      Nothing found
                  </div>
              )}
          </div>
       
          <div className="grid grid-cols-12 gap-2">

            {
                  smsCount && (Menus.map((menu, index) => (
                              <MonitoringCards
                                  key={index}
                                  title={menu.title}
                                  desc={menu.desc}
                                  icon={menu.icon}
                                  activeTable={activeTable}
                                  setActiveTable={setActiveTable}
                                  data={smsCount}
                              />
                          )))
                      
                    
            }

        
          </div>

        


          <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">


              <div className='mt-2 p-4'>
                  {/* Table */}
                  <div className="overflow-x-auto ">
                      {/* <input
              type="text"
              placeholder="Search..."
              class=" float-right px-4 mb-2 py-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
              value={searchQuery}
              onChange={event => setSearchQuery(event.target.value)}
            /> */}

                      <div class="relative float-right">
                          <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
                              <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                          </div>
                          <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
                              value={searchQuery}
                              onChange={event => setSearchQuery(event.target.value)}
                              placeholder="Search..." />
                          {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                      </div>
                      {failedMessagesLogs && failedMessagesLogs.length > 0 && (
                          <DataTableTemplate columns={columns} data={filteredData}
                                  title={"Patients List"}
                                contextActions={contextActions}
                                handleRowSelected={handleRowSelected}
                                toggledClearRows={toggledClearRows}
                                setToggleClearRows={setToggleClearRows}
                              isselectableRows={false}
                          />

                      )
                      }

                  </div>

                  <div className=" overflow-auto">
                      <CronJobsMonitoringData/>
                    </div>


              </div>
              
          </div>

    </div>
  )
}

export default Monitoring