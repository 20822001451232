import React, {useState,useEffect} from 'react'
import dayCounts from '../../utils/days.json';


// * initial state for form data
const initialState = {
    "id": '',
    "DayIndex": '',
    "MessageIndex": '',
    "Language": '',
    "MessageText": '',
}
const MessageTemplateModalInputFields = ({ selectedRow, submitForm, setEditModalOpen, isEdit }) => {
    // * get data from selected row
    // console.log("selectedRow", selectedRow);
    // const data = selectedRow[0] != null ? selectedRow[0] : initialState;
    // const data = initialState;
    const [formData, setFormData] = useState(initialState);
    // console.log("data", data);
    // * useEffect hook to set form data based on seelected row
    useEffect(() => {
        //  data = selectedRow[0] || initialState;
        if(selectedRow[0] != null){
            setFormData(selectedRow[0]);
        }
        else{
            setFormData(initialState);
        }
        console.log("formData", formData);
    }, [selectedRow]); 

    // * handle form input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value);
        setFormData({ ...formData, [name]: value });
    };
    
    
    // * handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        submitForm(formData, isEdit);
        setEditModalOpen(false);
        setFormData(initialState);
        
    };
    
  return (
      <>
      <form onSubmit={handleSubmit}>
          <div className="px-5 py-4">
              <div className="text-sm">
                  <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Add Message Template </div>
              </div>
              <div className="space-y-3">
                      <span className="text-rose-700"> All time mentioned are according to GMT+5</span>
                { !isEdit && (
                <div>
                  <div>
                      {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required /> : null}

                      <label className="block text-sm font-poppins font-medium my-2" htmlFor="name">Day <span className="text-rose-500">*</span></label>
                      {/* <input id="name" className="form-input w-full px-2 py-1 rounded-lg" type="text" required /> */}
                          <select id="DayIndex" defaultValue={formData?.DayIndex} name='DayIndex' onChange={handleInputChange} required class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option value="" selected={formData.DayIndex == "" ? true : false} disabled >Select Day</option>
                          {
                              Object.entries(dayCounts).map(([key, value]) => {
                                  return <option selected ={formData.DayIndex == value ? true:false }  value={value}>{key}</option>
                              })
                          }
                      </select>
                  </div>
                  <div>
                      <label className="block text-sm font-poppins font-medium my-2" htmlFor="MessageIndex">Message Sending Time <span className="text-purple-500">*</span></label>
                      {/* <input id="email" className="form-input w-full px-2 py-1 rounded-lg" type="email" required /> */}

                          <select id="messageIndex" name='MessageIndex'  required onChange={handleInputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {/* <option selected>Language</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Bangla">Bangla</option> */}
                    
                              <option value="" selected={formData.MessageIndex == "" ? true : false} disabled >Select Message Time</option>

                              <option selected={formData.MessageIndex == "1" ? 1 : 0} value="1">9:00 AM</option>
                              <option selected={formData.MessageIndex == "2" ? 1 : 0} value="2">12:00 PM</option>
                              <option selected={formData.MessageIndex == "3" ? 1 : 0} value="3">02:00 PM</option>
                              <option selected={formData.MessageIndex == "4" ? 1 : 0} value="4">05:00 PM</option>
                              <option selected={formData.MessageIndex == "5" ? 1 : 0} value="5">07:00 PM</option>
                              {/* <option selected={formData.MessageIndex == "6" ? 1 : 0} value="6">10:00 PM</option> */}
                          {/* <option value="7">Message 7</option> */}
                          {/* <option value="8">Message 8</option> */}
                      </select>
                  </div>
                  <div>
                      <label className="block text-sm font-poppins font-medium my-2" htmlFor="email">Language <span className="text-purple-500">*</span></label>
                      {/* <input id="email" className="form-input w-full px-2 py-1" type="email" required /> */}
                          <select id="language"  name='Language' onChange={handleInputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option value="" selected={formData.Language == "" ? true : false} disabled >Select Language</option>

                          {/* <option selected>Language</option>     */}
                              <option selected={formData.Language == "Urdu" ? 1 : 0} value="Urdu">Urdu</option>
                              <option selected={formData.Language == "Bangla" ? 1 : 0} value="Bangla">Bangla</option>
                      </select>
                  </div>
                </div>
                      )}
                  <div>
                      <label className="block text-sm font-poppins font-medium my-2" htmlFor="feedback">Message <span className="text-purple-500">*</span></label>
                      <textarea name='MessageText' value={formData?.MessageText} onChange={handleInputChange} id="feedback" className="form-textarea w-full px-2 py-1" rows="4" required></textarea>
                  </div>
              </div>
          </div>
          {/* Modal footer */}
          <div className="px-5 py-4 border-t border-slate-200">
              <div className="flex flex-wrap justify-end space-x-2">
                  <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button>
                      <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-indigo-600 text-white">Save</button>
              </div>
          </div>
      </form>
      </>
  )
}

export default MessageTemplateModalInputFields