import { getAxiosInstance } from "../Config";

//* get all sites from database
export const fetchAllSites = async () => {
    const axiosInstance = await getAxiosInstance();

    try {
        return axiosInstance
            .get("/site/getAllSites")
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(`Error: ${response.data}`);
                }
                return response.data;
            })
            .catch((error) => {
                console.error(error.message);
                return error.message;
            });
    } catch (error) {
        console.error(error.message);
        return error.message;

    }
}

// * update site in database
export const updateSite = async ( data) => {
    const axiosInstance = await getAxiosInstance();
     
    try {
      
             return  axiosInstance
              .patch("/site/updateSite", data)
              .then((response) => {
                if (response.status !== 200) {
                  throw new Error(`Error: ${response.data}`);
                }
                return response.data;
              }).catch((error) => {
                console.error(error.message);
                return error.message;
                // alert(error.message);
              }
            );
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}


// * create new site in database
export const createSite = async ( data) => {
    const axiosInstance = await getAxiosInstance();
     console.log("data", data);
    try {
      
             return  axiosInstance
              .post("/site/createSite", data)
              .then((response) => {
                if (response.status !== 200) {
                  throw new Error(`Error: ${response.data}`);
                }
                return response.data;
              }).catch((error) => {
                console.error(error.message);
                return error.message;
                // alert(error.message);
              }
            );
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        // alert(error.message);
        
    }
}

// * delete site in database
export const deleteSite = async (ids) => {
    const axiosInstance = await getAxiosInstance();
    try {
             return axiosInstance
               .delete("/site/deleteSite", { data: { ids } })
               .then((response) => {
                 if (response.status !== 200) {
                   throw new Error(`Error: ${response.data}`);
                 }
                 return response.data;
               })
               .catch((error) => {
                 console.error(error.message);
                 return error.message;
                 // alert(error.message);
               });
             
    } catch (error) {
        console.error(error.message);
        return error.message;
        
    }
}