import Login from "./Pages/Login";
import {  Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/privateRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import UserManual from "./Pages/Dashboard/UserManual";


// import HomePage from "./Pages/Dashboard/HomePage";
// import PatientPortal from "./Pages/Dashboard/PatientPortal";
// import MessagePortal from "./Pages/Dashboard/MessagePortal";
import {
  Home,
  ReportingDashboard, PatientPortal,MessageTemplate, PatientDetails,
  AdminPortal, GroupManagement, GroupDetails,
  Monitoring
} from "./Pages/Dashboard";
import Loader from "./Loader";
function App() {

  // * disabled console.log, console.warn, console.error in production

  if (process.env.REACT_APP_MODE === "production") {

    console.log = () => { };

    console.warn = () => { };

    console.error = () => { };

  }
  
  // const [user, setUser] = useState(null);
  return (
    <>
      {/* <AuthContext.Provider > */}
      <ToastContainer />
      {
        //* Public Routes
      }
      <Routes>
          <Route path="/login" element={<Login />} />
          {
            //* Protected Routes
          }
          {/* <Loader /> */}
          <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/reporting-dashboard" element={<ReportingDashboard />} />
            <Route path="/patient-management" element={<PatientPortal />} />
          <Route path="/group-management" element={<GroupManagement />} />
          <Route path="/group-details/:id" element={<GroupDetails />} />
            {/* <Route path="/message-portal" element={<MessagePortal />} /> */}
          <Route path="/patient-details/:regId" element={<PatientDetails />} />
          <Route path="/template-management" element={< MessageTemplate />} />
          <Route path="/admin-portal" element={< AdminPortal />} />
          <Route path="/monitoring" element={< Monitoring />} />
          <Route path="/help" element={< UserManual />} />
          </Route>

        {/* <PrivateRoutes>
          <Route path="/" element={<HomePage />} />
          <Route path="/patient-management" element={<PatientPortal />} />
          <Route path="/group-management" element={<GroupManagement />} />
          <Route path="/patient-details/:regId" element={<PatientDetails />} />
          <Route path="/template-management" element={<MessageTemplate />} />
          <Route path="/admin-portal" element={<AdminPortal />} />
        </PrivateRoutes> */}
      </Routes>
        {/* </AuthContext.Provider> */}
    </>
    // <Login/>
  );
}

export default App;
