// get data from redcap api using axios
// import selectedCoulmns from './fields.json';

const config = {
  api_token: "C21178B06C5967289A730C4545204B03",
  api_url: "https://www.arkredcap.com/api/",
};

// const selectedFields = Object.keys(selectedCoulmns);

// console.log(selectedFields);

const AllFields = {
  token: config.api_token,
  content: "record",
  format: "json",
  type: "flat",
  forms: "screening_form_quit_4_tb_trial",
  filterLogic: "[rc_crf_sl] > 5",
  rawOrLabel: "label",
  rawOrLabelHeaders: "label",
  // exportCheckboxLabel: "true",
  exportSurveyFields: "true",
};


const Tablefields = {
  token: config.api_token,
  content: "record",
  format: "json",
  type: "flat",
  records: ["6"],
  forms: "screening_form_quit_4_tb_trial",
  filterLogic: "",
      rawOrLabel: 'label',
      rawOrLabelHeaders: 'label',
      exportCheckboxLabel: 'true',
      exportSurveyFields: 'true',
  // exportCheckboxLabel: true,

  // content: "record",
  //     action: 'export',
  //     format: 'json',
  //     type: 'flat',
  //     csvDelimiter: '',
  //     records: ['6'],
  //     fields: ['crf_screen_cid','crf_screen_name','crf_screen_age'],
  //     forms: ['screening_form_quit_4_tb_trial'],
  //     rawOrLabel: 'label',
  //     rawOrLabelHeaders: 'label',
  //     exportCheckboxLabel: 'true',
  //     exportSurveyFields: 'true',
  //     exportDataAccessGroups: 'false',
  //     returnFormat: 'json',
  //     dateRangeBegin: '2023-01-01 00:00:00',
  //     dateRangeEnd: '2023-01-30 00:00:00'
};




const options = {
  method: "POST",
  body: new URLSearchParams(AllFields).toString(),
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const tableOptions = {
  method: "POST",
  body: new URLSearchParams(Tablefields).toString(),
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};


export const getRedcapData = async () => {
    const response = await fetch(config.api_url, options);
       const JSONresponse = await response.json();
       console.log("JSONresponse1", JSONresponse);
       return JSONresponse;
      }
      
export const getRedcapTableData = async () => {
        const response = await fetch(config.api_url, tableOptions);
        const JSONresponse = await response.json();
        // console.log("JSONresponse2", JSONresponse);
    return JSONresponse;
}


// const axios = require('axios');

// const data = {
//     token: 'C21178B06C5967289A730C4545204B03',
//     content: 'record',
//     action: 'export',
//     format: 'json',
//     type: 'flat',
//     csvDelimiter: '',
//     records: ['6'],
//     fields: ['crf_screen_cid','crf_screen_name','crf_screen_age'],
//     forms: ['screening_form_quit_4_tb_trial'],
//     rawOrLabel: 'label',
//     rawOrLabelHeaders: 'label',
//     exportCheckboxLabel: 'true',
//     exportSurveyFields: 'true',
//     exportDataAccessGroups: 'false',
//     returnFormat: 'json',
//     dateRangeBegin: '2023-01-01 00:00:00',
//     dateRangeEnd: '2023-01-30 00:00:00'
// };

// axios({
//     method: 'post',
//     url: 'https://www.arkredcap.com/api/',
//     data: data,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// })
// .then(response => {
//     console.log(response.data);
// })
// .catch(error => {
//     console.log(error);
// });

