import React, { useState, useEffect } from 'react';
import { patientEnrollmentReport } from '../../../api/ReportingDashboard/reportingAPIs';
import {getEnrollmentStats} from '../../../api/Home/api';

const PatientEnrollmentStatsByCountry = ({Region}) => {
    const [enrollmentData, setEnrollmentData] = useState({});

    const enrollmentStatsByCountry = async () => {
        try {
            const response = await getEnrollmentStats(Region);
            setEnrollmentData(response[0]);
        } catch (error) {
            console.error('Error fetching patient enrollment stats by country: ', error);
        }
    }

    useEffect(() => {
        enrollmentStatsByCountry(Region) 
    }, [Region]); // The empty dependency array ensures this effect runs only once on component mount

    return (
        <div className="flex flex-col  bg-white shadow-lg rounded-sm border border-slate-200">
            <header className="px-5 py-4 border-b border-slate-100 flex items-center justify-between">
                <div>
                    <h2 className="font-semibold text-slate-800">Patient Enrollment Over Time</h2>
                </div>
                <div className="relative flex items-center justify-center w-4 h-4 rounded-full bg-rose-100 ml-3" aria-hidden="true">
                    <div className="absolute w-1.5 h-1.5 rounded-full bg-rose-500"></div>
                </div>
            </header>
            <div className="flex flex-col h-full">
                {/* <div className="px-5 py-3">
                    <div className="flex items-center">
                        <div className="relative flex items-center justify-center w-4 h-4 rounded-full bg-rose-100 mr-3" aria-hidden="true">
                            <div className="absolute w-1.5 h-1.5 rounded-full bg-rose-500"></div>
                        </div>
                    </div>
                </div> */}
                <div className="grow px-5 pt-3 pb-1">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="text-xs uppercase text-slate-400">
                                <tr>
                                    <th className="py-2">
                                        <div className="font-semibold text-left">Type</div>
                                    </th>
                                    <th className="py-2">
                                        <div className="font-semibold text-right">Count</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-sm divide-y divide-slate-100">
                                {Object.entries(enrollmentData).map(([type, count], index) => (
                                    <tr key={index}>
                                        <td className="py-2">
                                            <div className="text-left">{type}</div>
                                        </td>
                                        <td className="py-2">
                                            <div className="font-medium text-right text-slate-800">{count}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PatientEnrollmentStatsByCountry;
