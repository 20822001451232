import React, { useState, useEffect } from 'react';
import MessagePortalCard from '../../Components/Cards/MessagePortalCard';
import PatientDetailsCard from '../../Components/Cards/PatientDetailsCard';
import { getPatientDetails, getSmsCountByPatientId, updatePatientstatus, resetManualUpdate } from '../../api/Patient';
import { useParams } from 'react-router-dom';
import PageTitle from '../../Components/utils/PageTitle';
import NotesAndActivities from '../../Components/Cards/NotesAndActivities';
import PatienDayCountChart from '../../Components/Cards/PatienDayCountChart';
import DoctorImage from '../../Components/Cards/DoctorImage';
import ManagePatientStatus from '../../Components/PatientProfile/ManagePatientStatus';
import { toast } from 'react-toastify';

const PatientDetails = () => {
    const [PatientInformation, setPatientInformation] = useState([]);
    const [SmsCount, setSmsCount] = useState([]);
    const [isActive, setisActive] = useState(null);

    const { regId } = useParams();

    const getPatientInformation = () => {
        getPatientDetails(regId)
            .then((res) => {
                setPatientInformation(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const getSmsCount = () => {
        getSmsCountByPatientId(regId)
            .then((res) => {
                setSmsCount(res.data[0]);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const updatePatientInformation = (status,phoneNum) => {
        if (status === 'Active') {
            setisActive(true);
        } else if (status === 'Inactive') {
            setisActive(false);
        }
        console.log('myisActive', status);
        updatePatientstatus(status, phoneNum, regId)
            .then((res) => {
                console.log(res?.data?.PatientStatus);
                // setisActive(res?.data?.PatientStatus === 'Active' ? true : false);
                setPatientInformation(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const resetManualUpdateFlag = () => {
        resetManualUpdate(regId).then((res) => {
            toast('Patient Reset to Automatic Update')
            setPatientInformation(res.data)
        }
            ).catch((err) => {
                console.log("Failed to reset", err.message);   
            })
    }
    useEffect(() => {
        getPatientInformation();
        getSmsCount();
    }, []);

    const handleStatusChange = (status) => {
        console.log("status changed", status);
      
        updatePatientInformation(status); 
    };

    return (
        <div>
            <PageTitle title='Patient Profile' />
            
            {/* <div className='flex flex-1 justify-start'>
                {PatientInformation? 
                    <ManagePatientStatus
                        currentStatus={PatientInformation.PatientStatus}
                        setisActive={setisActive}
                        isActive={isActive}
                        changeStatus={handleStatusChange}
                    />
                    : null    
            }
               
            </div> */}

            {
                PatientInformation?.manuallyUpdated ? <div className='flex flex-1 justify-start'>
                    <button type="button" class="text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" onClick={
                        resetManualUpdateFlag
                    }
                    >
                        Reset Manual Update
                    </button>
                    </div>:null
            }

            <div className=' flex flex-wrap justify-between'>
                <PatientDetailsCard PatientInformation={PatientInformation} updateInformation={updatePatientInformation} />
                <div className='md:w-1/3'>
                    <DoctorImage />
                </div>
            </div>

            <div className='grid grid-col-1 md:grid-cols-6 gap-x-2 gap-y-3 row-auto'>
                <NotesAndActivities regId={regId} />
                <PatienDayCountChart date={PatientInformation.CreatedAt} />
                <MessagePortalCard
                    SmsCount={SmsCount}
                    country={PatientInformation.Country}
                    phone={PatientInformation.PhoneNumber}
                />
            </div>
        </div>
    );
};

export default PatientDetails;
