import React,{useState,useEffect,useMemo} from 'react'
import PageTitle from '../../Components/utils/PageTitle';
import GroupMemberCards from '../../Components/Cards/GroupMemberCards';
import NonGroupMemberTable from '../../Components/Cards/NonGroupMemberTable';
import { useParams } from 'react-router-dom';
import { sendGroupSMS } from '../../api/GroupManagement/sendGroupSMS';
import { useLocation } from 'react-router-dom';
import { AiOutlineSend, } from 'react-icons/ai';

import { getNonGroupMembersByGroupId, getGroupMembersByGroupId, bulkAddGroupMembers, bulkRemoveGroupMembers } from '../../api/GroupManagement/api';
import { toast } from 'react-toastify';


const GroupDetails = () => {
 const [nonGroupMembers, setnonGroupMembers] = useState([]);
 const [GroupMembers, setGroupMembers] = useState([]);
  const [message, setMessage] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const location = useLocation();
  const { groupName } = location.state;


  // * GET QUERY PARAMS USING USEPARAMS
  const { id } = useParams();

 // * Fetching non group members
  const getNonGroupMembers = async () => {
      console.log("groupId",id);

        // const res = await getNonGroupMembersByGroupId(id);
         getNonGroupMembersByGroupId(id).then((res)=>{
            console.log("non group members",res);
            setnonGroupMembers(res);
         }).catch((err)=>{
              console.log(err.message);
          })
  };

  // * Fetching group members
  const getGroupMembers = async () => {
      getGroupMembersByGroupId(id).then((res)=>{
        console.log("group members",res);
        setGroupMembers(res);
      }).catch((err)=>{
          console.log(err.message);
      })
    // const res = await getGroupMembersByGroupId(id);
    // console.log("group members",res.data);
    // setGroupMembers(res.data);
  };

  useEffect(() => {
    getNonGroupMembers();
    getGroupMembers();
  }, []);

  // * Handle submit action for adding and removing group members
  const handleSubmit = async (actionType,rows)=>{
    switch(actionType){
        case "add":
            console.log("add",rows);
            await bulkAddGroupMembers(id,rows);
              getNonGroupMembers();
              getGroupMembers();

            break;
        case "remove":
            console.log("remove",rows);
            await bulkRemoveGroupMembers(id,rows);
              getNonGroupMembers();
              getGroupMembers();
            break;
        default:
            console.log("no row selected");
    }
  }

  // * send message to all group members
  const sendMessageToAllGroupMembers = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("send message to all group members");
     await sendGroupSMS(id,message).then((resp)=>{
      console.log(resp);
      setResults(resp.results);
      setLoading(false);
       setMessage('');
      
      // toast.success(resp.message);
      // setMessage('');
    }).catch((err)=>{
      toast.error(err.message);
      setLoading(false);
      setResults([]);
      setMessage('');

    });
    // console.log(res);
    // if(res.ok){
    //   toast.success("Message sent successfully");
    //   setMessage('');
    // }
    // else{
    //   toast.error("Message not sent");
    // }
  }


  //* search filter
  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return nonGroupMembers;
    }

    return nonGroupMembers.filter(row => {
      return Object.values(row).some(value => {
        if (typeof value !== 'string') {
          return false;
        }
        return value?.toLowerCase().includes(searchQuery?.toLowerCase())
      }
      );
    });
  }, [nonGroupMembers, searchQuery]);

  return (
    <>
      <PageTitle title={groupName ? groupName: "Group Details"} />
      <div className="grid grid-cols-12 gap-6">
        <NonGroupMemberTable groupId={id} nonGroupMembers={filteredData} searchQuery={searchQuery} setSearchQuery ={setSearchQuery} submitAction={handleSubmit} />
          {/* //  <h1>No Non Group Members</h1> */}
        <GroupMemberCards groupId={id} GroupMembers={GroupMembers} submitAction={handleSubmit} />  
          {/* // <h1>No Group Members</h1> */}
        {/* <GroupMemberCards groupId={id} GroupMembers={GroupMembers}  /> */}
      </div>
      <div className="grid grid-cols-12 gap-6 my-2">
        {/* create send message area and send message to all group memebers. */}
        <div className="flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100 flex items-center">
            {/* <h2 className="font-poppins font-bold text-slate-800">Non Group Members</h2> */}
          </header>
          <div className="px-5 py-1 hidden">
            <div className="flex flex-wrap">
              {/* Unique Visitors */}
              <div className="flex items-center py-2">
                <div className="mr-5">
                  <div className="flex items-center">
                    <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">24.7K</div>
                    <div className="text-sm font-poppins font-medium text-emerald-500">+49%</div>
                  </div>
                  <div className="text-sm text-slate-500">Unique Visitors</div>
                </div>
                <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
              </div>
              {/* Total Pageviews */}
              <div className="flex items-center py-2">
                <div className="mr-5">
                  <div className="flex items-center">
                    <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">56.9K</div>
                    <div className="text-sm font-poppins font-medium text-emerald-500">+7%</div>
                  </div>
                  <div className="text-sm text-slate-500">Total Pageviews</div>
                </div>
                <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
              </div>
              {/* Bounce Rate */}
              <div className="flex items-center py-2">
                <div className="mr-5">
                  <div className="flex items-center">
                    <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">54%</div>
                    <div className="text-sm font-poppins font-medium text-amber-500">-7%</div>
                  </div>
                  <div className="text-sm text-slate-500">Bounce Rate</div>
                </div>
                <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
              </div>
              {/* Visit Duration*/}
              <div className="flex items-center">
                <div>
                  <div className="flex items-center">
                    <div className="text-3xl font-poppins font-bold text-slate-800 mr-2">2m 56s</div>
                    <div className="text-sm font-poppins font-medium text-amber-500">+7%</div>
                  </div>
                  <div className="text-sm text-slate-500">Visit Duration</div>
                </div>
              </div>
            </div>
          </div>
          {/* Chart built with Chart.js 3 */}
          <div className="grow">
        {/* // * create send message form with text area and send message to all group memebers. */}
            <div className="p-5">
              <form>
                <div className="flex flex-wrap    mx-3 mb-6">
                  <div className="w-full h-full px-3">
                    <label className="block  tracking-wide text-start text-gray-700 text-lg font-poppins font-bold mb-2" for="grid-password">
                      Send Group Message !
                    </label>
                    <textarea className="appearance-none block w-full  text-gray-700 border border-gray-200  rounded-lg py-5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" value={message} type="password" placeholder="Enter Your Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                    {/* <p className="text-gray-600 text-xs italic">Enter Your Message</p> */}
                  </div>
                </div>
                <div className="flex flex-wrap justify-end mx-3 mb-6">
                  <div className="w-full p-3   flex justify-end rounded-lg">
                    <button className="  bg-[#0F6CBD] hover:bg-indigo-600 text-white text-center justify-center items-center text-sm font-poppins font-semibold pt-1 px-4 rounded-full " onClick={sendMessageToAllGroupMembers}>
                      Send Message <AiOutlineSend className="inline-block ml-2 -mt-1" />
                    </button>
                  </div>
                </div>
              </form>
              </div>
              
            {/* {loading && <p>Loading...</p>}
            {results?.length > 0 && (
              <div>
                <h2>SMS Results</h2>
                <ul>
                  {results?.map((result, index) => (
                    <li key={index}>
                      {"TBRegNum: " + result?.id}: {result?.success ? 'Success' : 'Failure'}
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
          </div>
        </div>   


      </div>
    </>
  )
}

export default GroupDetails