import React,{useState,useEffect} from 'react'
import { AiOutlineSend, AiFillDelete } from 'react-icons/ai';
import moment from 'moment';
import { getPatientNotesByPatientId, addPatientNotesByPatientId, deletePatientNote } from '../../api/Patient';
import { toast } from 'react-toastify';
const NotesAndActivities = ({ regId }) => {

    const [message, setMessage] = useState('');
    const [Notes, setNotes] = useState([]);


    useEffect(() => {
        getNotes(regId);
    }, [regId])
    

    const getNotes = async(regId) => {
        const res = await getPatientNotesByPatientId(regId);
        console.log(res);
        setNotes(res);
    }
    const handleSubmit =(e) =>{
        e.preventDefault();
        console.log(message);
        console.log(regId);
        addPatientNotesByPatientId(regId,message).then((res)=>{
            if(res.status === 200){
                toast.success('Note Added Successfully');
                setMessage('');
                getNotes(regId);
            }
        }).catch((err)=>{
            toast.error('Something went wrong');
        })
    }

    const deleteANote =async(id)=>{
        console.log("delete");
        console.log(id);
        await deletePatientNote(id).then((res)=>{
            if(res.status === 200){
                toast.success('Note Deleted Successfully');
                 getNotes(regId);
            }
        }).catch((err)=>{
            toast.error('Something went wrong');
        })
    }

  return (
      <div className='md:col-span-2 h-auto  min-h-max'>
      <div className="flex flex-col col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
          <header className="px-5 py-4 border-b border-slate-100">
              <h2 className="font-poppins font-semibold text-slate-800">Notes & Activities</h2>
          </header>
              <div className=" grow p-5">
                  <form onSubmit={handleSubmit}>
                      <div className="flex flex-wrap    mx-3 mb-6">
                          <div className="w-full h-full px-3">
                              <label className="block uppercase tracking-wide text-start text-gray-700 text-xs font-poppins font-bold mb-2" for="grid-password">
                                  Patient Notes
                              </label>
                              <textarea className="appearance-none block w-full text-gray-700 border border-gray-200 rounded rounded-lg py-5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="patient-note" value={message} onChange={(e)=>{setMessage(e.target.value)}} placeholder="Enter Your Note">

                              </textarea>
                              {/* <p className="text-gray-600 text-xs italic">Enter Your Message</p> */}
                          </div>
                      </div>

                      {/* section for showing notes with datetime and delete button */}
                      <div>
                          {/* <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-poppins font-semibold p-2">Today</header> */}
                          <ul className="my-1">
                              {/* Item */}
                              {Notes && Notes.map((note,index) => {

                                  return (<li key={index} className="flex px-2">
                                  <div className="w-7 h-7 rounded-full shrink-0 bg-secondary my-2 mr-3">
                                      <svg className="w-7 h-7 fill-current text-indigo-50" viewBox="0 0 36 36">
                                          <path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                                      </svg>
                                  </div>
                                  <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                            <div className="grow flex justify-between">
                                              <div className="self-center"><p className="font-poppins font-medium text-slate-800 hover:text-slate-900" href="#0">{note.Note}</p>  <p className="font-poppins font-small text-slate-600" href="#0">{moment(note.CreatedAt).format('YYYY-MM-DD')}</p> </div>
                                                <div className="shrink-0 self-end ml-2">
                                                    {/* TODO: NEED TO CREATE DELTE FUNCTIONALITY */}
                                                    <a className="font-poppins font-medium text-indigo-500 hover:text-red-600"  onClick={()=>{deleteANote(note.id)}} ><AiFillDelete size={20} /></a>
                                                </div>
                                            </div>
                                  </div>
                              </li>)
                            })}

                          </ul>

                      </div>

                      <div className="flex flex-wrap justify-end mx-3 ">
                          <div className="w-full p-2  flex justify-end rounded-lg">
                              <button className=" bg-[#0F6CBD] hover:bg-[#1b82dd] text-white text-center items-center justify-center text-sm font-poppins font-semibold pt-1 px-3 rounded-full " >
                                  Add Note <AiOutlineSend className="inline-block ml-2 -mt-1" />
                              </button>
                          </div>
                      </div>
                  </form>
                    
      </div>
      </div>        
      </div>        
  )
}

export default NotesAndActivities