import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import PageTitle from '../../Components/utils/PageTitle';
import DataTableTemplate from '../../Components/Tables/DataTableTemplate';
import { getGroupData, createGroup, deleteGroup, updateGroup } from '../../api/GroupManagement/api';
import { toast } from 'react-toastify';
import ModalLayout from '../../Components/Modals/ModalLayout';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthProvider';

import moment from 'moment/moment';


const GroupManagement = () => {

    // Columns for Data Table
    const columns = [
        
        {
            name: 'Group Name',
            selector: row => row?.GroupName,
            sortable: true,
            // cell: row => <a href={row.} target="_blank" rel="noopener noreferrer">{row.website}</a>
            // cell: row => <Link className='underline  text-blue-600' to={"/patient-Details/" + row.TBRegistrationNumber}>{row.Name}</Link>
        },
        {
            name: 'Member Count',
            // selector: row => "Message # "+row.MessageIndex,
            selector: row => row?.memberCount,
            sortable: true,
        },
        {
            name: 'Creation Date',
            selector: row => moment(row?.CreatedAt).format('DD-MM-YYYY'),
            sortable: true,
        },
        {
            name: 'Action',
            selector: 'Action',
            cell: row => <Link className='btn btn-sm rounded-lg bg-primary  text-white hover:bg-blue-600 float-right right-0' state={{ groupName: row?.GroupName }} to={"/group-details/" + row?.id}>Manage</Link>

        },

    ];
    const initialState = {
        GroupName: "",
    };

    const [formData, setformData] = useState(initialState);
    const [groups, setgroups] = useState([]);
    const [AddGroupModalOpen, setAddGroupModalOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');


    const { user } = useContext(AuthContext);
    console.log(user);

    // * Action context for selected rows
    const contextActions = useMemo(() => {
        return (
            <>
                <div className="flex justify-end gap-2 ">
                    {selectedRows.length < 2 ?
                        <button type="button" key="edit" onClick={(e) => { e.stopPropagation(); handleAction('edit', selectedRows) }} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                            >
                                <path d="M6.3 12.3l10-10a1 1 0 011.4 0l4 4a1 1 0 010 1.4l-10 10a1 1 0 01-.7.3H7a1 1 0 01-1-1v-4a1 1 0 01.3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 012 0v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2h6a1 1 0 010 2H4v14h14v-6z" />
                            </svg>
                            Edit
                        </button> : null
                    }
                    <button type="button" key="delete" onClick={() => handleAction('delete', selectedRows)} class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-poppins font-medium rounded-md text-white bg-dark-purple hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                        >
                            <path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" />
                        </svg>
                        Delete
                    </button>
                </div>
            </>
        );
    }, [selectedRows]);

    // * handle row selection (checkbox)
    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    // * handle actions on selected rows
    const handleAction = async (action, row) => {

        switch (action) {
            case 'edit':
                console.log("open edit modal");
                console.log("selected row:", row[0]);
                setformData(row[0]);
                setAddGroupModalOpen(true);
                break;
            case 'delete':
                const confirm = window.confirm("Are you sure you want to delete this record?");
                if (confirm) {
                    // extract ids of all selected row
                    const ids = row.map((item) => item.id);
                    console.log(ids);
                    await deleteGroup(ids);
                    toast.success("Group  Deleted Successfully");
                    fetchGroups();
                    setToggleClearRows(!toggledClearRows);

                    // create an array of objects with just the id property
                    // const idObjects = ids.map((id) => ({ id }));
                    // console.log(idObjects);
                    // delete selected rows
                    // await deleteData(currentTable, idObjects);
                    // await deleteData(currentTable, ids);
                    // fetchData(currentTable);
                    // setToggleClearRows(!toggledClearRows);
                }

                break;
            default:
                break;
        }
    };

    // * handle form input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setformData({ ...formData, [name]: value });
    };
    // * handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        setAddGroupModalOpen(false);
        formData.id ?
            (
                updateGroup(formData).then((res) => {
                    console.log(res);
                    toast.success("Group Updated Successfully");
                    fetchGroups();
                }).catch((err) => {
                    console.log(err);
                    toast.error("Error Creating Group");
                }).finally(() => {
                    setformData(initialState);
                })
            ) : (
                createGroup(formData).then((res) => {
                    console.log(res);
                    toast.success("Group Created Successfully");
                    fetchGroups();
                }).catch((err) => {
                    console.log(err);
                    toast.error("Error Creating Group");
                }).finally(() => {
                    setformData(initialState);
                })
            )
        // setFormData(initialState);
    };

    const fetchGroups = async () => {
        try {
            const res = await getGroupData();
            if (res.length > 0) {
                setgroups(res);
                toast.success("Groups Fetched Successfully");
            }
            else {
                setgroups([]);
                toast.error("No Groups Found");
            }
        } catch (error) {
            setgroups([]);
            console.log(error);
            toast.error("Error Fetching Groups");
        }
    }

    useEffect(() => {
        fetchGroups();
    }, [])

    //* search filter
    const filteredData = useMemo(() => {
        if (!searchQuery) {
            return groups;
        }

        return groups.filter(row => {
            return Object.values(row).some(value => {
                if (typeof value !== 'string') {
                    return false;
                }
                return value?.toLowerCase().includes(searchQuery?.toLowerCase())
            }
            );
        });
    }, [groups, searchQuery]);

    return (<>
        <PageTitle title="Group Management" />

        {/* Right: Actions */}

        {
       (user?.role === "ADMIN" || user?.role === "SUPER_ADMIN") ? (

            <div className="grid grid-flow-col sm:auto-cols-max justify-end px-3 sm:justify-end gap-2">
                {/* Sync DB button */}
                    <button className="btn mb-2 rounded-lg bg-[#0F6CBD] hover:bg-indigo-600 text-white xs:px-2" onClick={(e) => { e.stopPropagation(); setAddGroupModalOpen(true); }}>
                    {/* <GrAdd className='text-white' size={20} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20a10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16z" /></svg>

                    <span className=" xs:block ml-2  "> New Group</span>
                </button>
            </div>
        ):null}

        <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">

            <div className='mt-4 p-2'>
                {/* Table */}
                {groups.length > 0 ? (
                    <div className="overflow-x-auto ">
                        <div class="relative float-right">
                            <div class="absolute inset-y-0 left-0 mb-1 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <input type="search" id="search" class="block flo p-2 mb-2 pl-10 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-gray-500 focus:border-indigo-500"
                                value={searchQuery}
                                onChange={event => setSearchQuery(event.target.value)}
                                placeholder="Search..." />
                            {/* <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-poppins font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                        </div>
                        <DataTableTemplate columns={columns} data={filteredData}
                            title={"Manage Groups"}
                            contextActions={contextActions}
                            handleRowSelected={handleRowSelected}
                            toggledClearRows={toggledClearRows} setToggleClearRows={setToggleClearRows}
                        />

                    </div> ) : ( <div className="flex flex-col items-center justify-center p-5">No Data Found </div> )}
                
            </div>
        </div>

        <ModalLayout id="groupmanagement-modal" modalOpen={AddGroupModalOpen} setModalOpen={setAddGroupModalOpen} title="Manage Group">
            <div className="flex flex-col w-full">
                <form onSubmit={handleSubmit}>
                    <div className="px-5 py-4">
                        {/* <div className="text-sm">
                              <div className="font-poppins font-medium text-slate-800 mb-3 text-center">Add New Group .. 🙌</div>
                          </div> */}
                        <div className="space-y-3">
                            <div>
                                {formData.id ? <input id="id" value={formData.id} className="form-input w-full px-2 py-1 rounded-lg" type="hidden" required /> : null}

                                <label className="block text-sm font-poppins font-medium mb-1" htmlFor="name">Group Name <span className="text-rose-500">*</span></label>
                                <input id="name" className="form-input w-full px-2 py-1 rounded-lg" name="GroupName" onChange={handleInputChange} value={formData?.GroupName} type="text" required />

                            </div>

                        </div>
                    </div>
                    {/* Modal footer */}
                    <div className="px-5 py-4 border-t border-slate-200">
                        <div className="flex flex-wrap justify-end space-x-2">
                            <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setAddGroupModalOpen(false); }}>Cancel</button>
                            <button type="submit" className="btn-sm bg-[#0F6CBD] hover:bg-indigo-600 text-white">Send</button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalLayout>

    </>
    )
}

export default GroupManagement