import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnalyticsBySite } from "../../../api/ReportingDashboard/reportingAPIs";

const UserStats = () => {
    const [analyticsData, setAnalyticsData] = useState({});

    const getAnalyticsData = async () => {
        try {
            // Make an API call to fetch analytics data
            const response = await AnalyticsBySite();
            console.log(response);

            // Process the response data to set analyticsData
            const processedData = await processAnalyticsData(response.data);
            setAnalyticsData(processedData);
            console.log(processedData);
        } catch (error) {
            console.error('Error fetching analytics data: ', error);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, []);

    const processAnalyticsData = (data) => {
        const processedData = {
            PakistanIntervention: 0,
            PakistanControl: 0,
            BanglaIntervention: 0,
            BanglaControl: 0,
        };

        // Loop through the data and accumulate counts based on the criteria
        data.forEach((item) => {
            const { Site_Status, Country, Patient_Count } = item;
            if (Country === 'Pakistan') {
                if (Site_Status === 'active') {
                    processedData.PakistanIntervention += Patient_Count;
                } else {
                    processedData.PakistanControl += Patient_Count;
                }
            } else if (Country === 'Bangladesh') {
                if (Site_Status === 'active') {
                    processedData.BanglaIntervention += Patient_Count;
                } else {
                    processedData.BanglaControl += Patient_Count;
                }
            }
        });

        return processedData;
    };

    return (
        <div className="flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200">
            <header className="px-5 py-4 border-b border-slate-100">
                <h2 className="font-semibold text-2xl text-slate-800">Patients By Site</h2>
                <h3 className="font-semibold text-lg text-slate-600">Total : {
                (parseInt(analyticsData?.BanglaControl)+
                        parseInt(analyticsData?.BanglaIntervention)+
                        parseInt(analyticsData?.PakistanControl)+
                        parseInt(analyticsData?.PakistanIntervention))}</h3>
            </header>
            {/* Card content */}
            <div className="flex flex-col h-full">
                {/* Table */}
                <div className="grow px-5 py-3">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            {/* Table header with column names */}
                            <thead className="text-base uppercase text-slate-400">
                                <tr>
                                    <th className="py-3">
                                        <div className="font-semibold text-left">Site</div>
                                    </th>
                                    <th className="py-3">
                                        <div className="font-semibold text-right">Pakistan</div>
                                    </th>
                                    <th className="py-3">
                                        <div className="font-semibold text-right">Bangladesh</div>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-lg divide-y divide-slate-100">
                                {/* Row for "Intervention" site */}
                                <tr>
                                    <td className="py-3">
                                        <div className="text-left">Intervention</div>
                                    </td>
                                    <td className="py-3">
                                        <div className="font-semibold text-right text-slate-800">{analyticsData?.PakistanIntervention}</div>
                                    </td>
                                    <td className="py-3">
                                        <div className="font-semibold text-right text-slate-800">{analyticsData?.BanglaIntervention}</div>
                                    </td>
                                </tr>
                                {/* Row for "Control" site */}
                                <tr>
                                    <td className="py-3">
                                        <div className="text-left">Control</div>
                                    </td>
                                    <td className="py-3">
                                        <div className="font-semibold text-right text-slate-800">{analyticsData?.PakistanControl}</div>
                                    </td>
                                    <td className="py-3">
                                        <div className="font-semibold text-right text-slate-800">{analyticsData?.BanglaControl}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Card footer (optional) */}
                {/* <div className="text-right px-5 pb-4">
                    <Link className="text-base font-semibold text-indigo-500 hover:text-indigo-600" to="#0">
                        Generate Report -&gt;
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default UserStats;
