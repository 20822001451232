// Authentication API request using Axios
// ==================
import axios from 'axios';
// import {createAxiosInstance} from './Config';

  // * Login API request
  export const login = async (email, password) => {
      // await axios.post(
      //   `${process.env.REACT_APP_API_URL}/auth/login`,
      //   {
      //     email,
      //     password,
      //   }
      // ).then((res) => {
      //   console.log(res);
      //     return res;
      // }).catch((err) => {
      //   console.log(err);
      //   return err.response;

      //   });

      try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/login`,
            {
              email,
              password,
            }
          );
          return res;
      } catch (error) {
          console.log(error.response);
          return error.response;
      }
      


  };


    
export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    
    // Remove the token from the header
    // createAxiosInstance(null);
}
