import React,{useState,useEffect} from 'react'
import LoginCard  from "../Components/LoginCard";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../utils/isTokenExpired";
const Login = () => {

  const [isTokenValid, setisTokenValid] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");

  useEffect(() => {
    if (token && !isTokenExpired(token)) {
      setisTokenValid(true);
      navigate("/");
      console.log("token expired");
    }
  }, [token]);
  return (
    <>
      <LoginCard />
    </>
  )
}

export default Login;
