import React,{useState,useEffect} from 'react'
import { getPatientCount } from '../../../api/Home/api';


const PatientCountCards = ({ Region  }) => {
  console.log("Region", Region);


  const [CountData, setCountData] = useState([]);

  const fetchCountData = async (Region) => {
    try {
      const response = await getPatientCount(Region);
      setCountData(response);
    } catch (error) {
      setCountData([]);
      console.log(error);
    }
   
  }

  useEffect(() => {
    fetchCountData(Region);
  }, [Region]);




  return (
    <>
      <div className="item drop-shadow-xl">
        <div className="flex flex-col flex=1  border-gray-500 items-center justify-center p-3 text-start bg-white border rounded-2xl ">
          <div className="flex items-center   justify-self-stretch space-x-3">

            <div className=" flex flex-wrap justify-center items-end border border-gray-400 rounded-lg p-2 w-12 h-12 text-2xl text-gray-800" >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 1920 1792"><path fill="currentColor" d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5zM640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181zm704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181z" /></svg>
                    </div>
            <div className="space-y-0.5 color-[#081a51]  font-lg font-poppins  ml-2  text-left text-xl">
              <div> Total Patients</div>
              <div className="text-md  font-poppins font-semibold text-gray-800 ">{CountData && (CountData[0]?.total_count) || 0}</div>
            </div>
          </div>
          <div className=" pr-3 mb-2 mt-3 ml-8  justify-self-center  text-gray-500 lg:mb-3 ">
            <h3 className="text-base  place-items-center font-poppins font-light text-gray-900 ">
              Patient in Progress: {CountData && (parseInt(CountData[0]?.total_count) -  parseInt(CountData[0]?.completed_count)) || 0}
            </h3>
            <h3 className="text-base  place-items-start font-poppins font-light text-gray-900 ">
              Patient Completed: {CountData && (CountData[0]?.completed_count) || 0}
            </h3>
          </div>
          
        </div>
      </div>
      </>
  )
}

export default PatientCountCards